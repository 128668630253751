import React, { useRef, useState } from "react";
import { GetAppText, secondaryColor, StyleClass } from "../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import SvgComponent from "../../../components/SvgComponent";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { ProgressBar } from "../../../components/ProgressBar";
import { InputComponent } from "../../../components/InputComponent";

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay  } from "swiper/modules";
import { modalHandler } from "../../../../store/slices/modalSlice";
import "swiper/css";
import "swiper/css/navigation";
import { Loader } from "../../../components/Loader";
import { apiRoutes } from "../../../../apis/routes";
import { postRequest } from "../../../../apis/methods";
import { Image_URL } from "../../../../utils/BaseUrl";
const UrgentProjects = ({data,loading}) => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.value);
  const [addloading,setAddLoading]=useState(false)

  
const [price,setPrice]=useState("")
// const [prices, setPrices] = useState(data?.map(() => 0)); // Initialize prices state

// Update the price for a specific card
const handlePriceChange = (e, itemId) => {
  const value = e.target.value;
  setPrice(prevPrices => ({
    ...prevPrices,
    [itemId]: value === "" ? "" : parseFloat(value) // Ensure value is either a number or empty
  }));
};

  const swiperRef = useRef();
const navigate = useNavigate();
const handleClick=(item)=>{
  navigate(`/donation-project-details/${item?.id}`);
    // navigate(`/donars-bag`);
 
}
const handleProjectClick = (id) => {

  navigate(`/donation-project-details/${id}`);
};
// const data=[
//   {id:1,name:"Title 1",subName:""},
//   {id:2,name:"Title 2",subName:""},
//   {id:3,name:"Title 3",subName:""},
// ]

const handleSubmit = async (item) => {
  // Prevent multiple submissions
if (addloading) return; 
// Prevent multiple submissions

const val = price[item.id]; // Get the price for this specific item
  const data = {
    itemable_type: "project",
    itemable_id: item?.id,
    price: parseFloat(val)  
  };
  setAddLoading(true)
if(val){
  try {
    const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
    console.log("Response", response);
 
    if (response?.success) { 
      setAddLoading(false)
    
      dispatch(modalHandler({ type: "toast", msg: response?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

        setPrice(prevPrices => ({
          ...prevPrices,
          [item.id]: "" // Clear the price for this specific item
        }));
 
    } else {
      setAddLoading(false)

      dispatch(modalHandler({ type: "toastError", msg: response?.data[0]}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
        setPrice(prevPrices => ({
          ...prevPrices,
          [item.id]: "" // Clear the price for this specific item
        }));
     
    }
  } 
  catch (error) {
    console.error('Error fetching data:', error);
  } }
  else{
      setAddLoading(false)
    
      dispatch(modalHandler("priceError"));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])

  }

};

const handle_share=(item)=>{
  const currentURL = window.location.href;
// console.log("link",`${currentURL}donation-project-details/${item?.id}`)
const fullLink=`${currentURL}donation-project-details/${item?.id}`
    // Copy the link to the clipboard
    navigator.clipboard.writeText(fullLink).then(() => {
      dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
      setTimeout(()=>{
        dispatch(modalHandler(""));
      },100)
    });

}


  return (
    <div className="w-full h-full space-y-8">
 
      <div
        className={`flex justify-between  items-center ${
          language === "_ar" ? "flex-row-reverse" : ""
        }`}
      >
        <h1 className={`text-primary text-F32 font-semibold`}>
          {GetAppText("urgent_projects")}
        </h1>
        <div className="flex flex-row items-center space-x-2">
          <div
          onClick={() => swiperRef.current?.slidePrev()}
            className={`flex justify-center items-center p-2 cursor-pointer border-2 border-black rounded-full`}
            // disabled={!canScrollLeft}
          >
            <SvgComponent
              name={"ArrowLeft"}
              className={"w-6 h-6"}
              stroke={"black"}
              fill={"none"}
            />
          </div>
          <div
           onClick={() => swiperRef.current?.slideNext()}
            className={`flex justify-center items-center p-2 cursor-pointer border-2 border-black rounded-full`}
            // disabled={!canScrollRight}
          >
            <SvgComponent
              name={"ArrowRight"}
              className={"w-6 h-6"}
              stroke={"black"}
              fill={"none"}
            />
          </div>
        </div>
      </div>


 {     loading ?
             <div className="flex  w-full justify-center h-[300px] items-center">
             <Loader/>
             </div>:
      <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
      
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          // autoplay={{
          //   delay: 4000, // Set the delay in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Allows autoplay to continue after interaction
          // }}
     
          className="w-full h-full rounded-xl relative"
        >
          {
             loading ?
             <div className="flex  w-full justify-center h-[300px] items-center">
             <Loader/>
             </div>
             : data?.length>0?
            data?.map((item,index)=>(
              <SwiperSlide className=" rounded-xl">
              <div key={index} className={`w-full md:flex md:flex-row flex rounded-xl flex-col-reverse ${
                language === "_ar" ? "md:flex-row-reverse" : ""
              }  md:h-[600px]  }`}>

              {/* Content Container */}
              <div
                className={`md:w-7/12 bg-primary   relative md:py-16 py-8 px-8 md:px-16 ${
                  language === "_ar"
                    ? "md:rounded-tr-xl  md:rounded-br-xl "
                    : " md:rounded-tl-xl  md:rounded-bl-xl "
                }`}
              >
                <div className="absolute  top-0 right-0">
                  <img
                    alt=""
                    src={require("../../../../assets/images/Vector3.png")}
                  />
                </div>
                <div className="flex flex-col justify-between">
                <div className="relative">
                {/* Rest of your content */}
                <div className="mb-14">
                <div className={`flex items-center justify-between absolute z-20  w-full ${StyleClass()}`}>
                  <h1 className="text-white font-bold text-F20 right-0">
                    {/* {GetAppText("building_schools")} */}
                    {language === "_ar" ?item?.title_ar:item?.title}
                  </h1>
             
                    <ButtonComponent
                    tooltip={GetAppText("link_share")}
                    title={GetAppText("sharing")}
                    svg={
                      <SvgComponent
                        name={"ShareSvg"}
                        className={"w-4 h-4"}
                        stroke={"white"}
                        fill={"none"}
                      />
                    }
                    onClick={()=>{handle_share(item)}}
                    styles={"!py-2 !px-3 !hover:!bg-opacity-50  !text-F16"}
                    type="transparent"
                  />
                </div>
                </div>
                <div className="py-5">
                  <div className={`text-white text-F16 ${language === "_ar" ? "text-right" : "text-left"} line-clamp-4`}>
                  {language === "_ar" ?item?.description_ar:item?.description}
                  </div>
                </div>
                <div className={`flex items-center pt-3 justify-between ${StyleClass()}`}>
                  <div className={`flex items-center space-x-2 ${StyleClass()}`}>
                    <SvgComponent
                      name={"Clock"}
                      stroke={"white"}
                      fill={"none"}
                      className={"w-5 h-5"}
                    />
                    <span className="text-white text-F18">
                      {item?.ends_in} {GetAppText("day")}
                    </span>
                  </div>
                  <div className={`flex items-center space-x-2 ${StyleClass()}`} onClick={()=>handleProjectClick(item?.id)}>
                    <span className="text-secondary cursor-pointer hover:underline font-bold text-F18">
                      {GetAppText("details")}
                    </span>
                    <SvgComponent
                      name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                      stroke={secondaryColor}
                      fill={"none"}
                      className={"w-5 h-5"}
                    />
                  </div>
                </div>
                <div className="md:pt-16 pt-8">
                  <ProgressBar progressStyle={"!bg-[#697B84]"} value={item?.goal_percentage} />
                </div>
                <div className="pt-6  h-full">
                  <div
                    className={`flex items-center justify-between ${StyleClass()}`}
                  >
                    <div className="flex flex-col  space-y-2">
                      <span
                        className={`text-white text-F18 ${
                          language === "_ar" ? "text-right" : ""
                        }`}
                      >
                        {GetAppText("donated")}
                      </span>
                      <span className="text-white text-F18">{item?.donated_amount.toLocaleString()} {language === "_ar" ? "د.ب":"BHD"}</span>
                    </div>
                    <div className="flex flex-col  space-y-2">
                      <span
                        className={`text-white text-F18 ${
                          language === "_ar" ? "text-right" : ""
                        }`}
                      >
                        {GetAppText("goal")} 
                      </span>
                      <span className="text-white text-F18">{item?.goal_amount.toLocaleString()} {language === "_ar" ? "د.ب":"BHD"}</span>
                    </div>
                  </div>
          
                </div>
                </div>
                <div className="md:pt-12 pt-8">
                    <div className={`lg:flex   sm:space-y-0 space-y-3 items-center ${StyleClass()}`}>
                      <div className="lg:w-7/12">
                        <InputComponent
                          title={GetAppText("donation_amount")}
                          isTranslate={false}
                          type="number"  input_style={'!py-3 !text-black'} 
                          // value={price}  
                          // onChange={(e) => setPrice(e.target.value)}
                          value={price[item.id] || ""} // Bind to specific item's price
                          onChange={(e) => handlePriceChange(e, item.id)} // Update only this card's price
                        />
                      </div>
                      <div className={`lg:w-6/12 grid  justify-items-stretch`}>
                        <div
                          className={`flex items-center  space-x-2 ${StyleClass()} ${
                            language === "_ar"
                              ? "justify-self-start"
                              : "justify-self-end"
                          }`}
                        >
                          <div className="">
                            <ButtonComponent
                              title={GetAppText("donate")}
                              type="secondary"
                              styles={"whitespace-nowrap !text-F17 !px-8"}
                              onClick={()=>{handleClick(item)}}
                            />
                          </div>
                          <ButtonComponent
                          loading={addloading}
                          disabled={addloading}
                  styles={"!py-3 !px-3.5 !rounded-[8px]  !bg-[#697B84]"}
                  onClick={()=>{handleSubmit(item)}}
                  svg={
                    <SvgComponent
                      name={"Bag"}
                      className={"w-5 h-5"}
                      stroke={"white"}
                      fill={"none"}
                    />
                  }
                  title={""}
                  type="primary"
                />
                      
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
              {/* Image Container */}
              <div className={` md:w-6/12  h-full gap-0 items-center   ${
                language === "_ar" ?  "rounded-xl "
                    : " md:rounded-tr-xl  md:rounded-br-xl "
              }`}>

<img
                        alt=""
                        src={`${Image_URL}/${item?.image}`}
                        className={`h-full w-full  
                       
                          object-cover
                     
                        `}
                      />


             
      
            
            
                  {/* <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/7.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "" : "rounded-tr-xl"
                      }`}
                    />
                  </div> */}
          
                {/* <div className="flex items-center w-full">
                  <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/8.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "rounded-bl-xl" : ""
                      }`}
                    />
                  </div>
                  <div className="w-6/12 h-full">
                    <img
                      alt=""
                      src={require("../../../../assets/images/6.png")}
                      className={`h-full object-cover ${
                        language === "_ar" ? "" : "rounded-br-xl"
                      }`}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            </SwiperSlide>
            )):
            (!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
               </div>)
          }
 
      </Swiper>}
    </div>
  );
};
export default UrgentProjects