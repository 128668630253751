import React, { useState } from "react";

import { GetAppText } from "../../../utils";
import { ButtonComponent } from "../ButtonComponent";
import { ProgressBar } from "../ProgressBar";
import SvgComponent from "../SvgComponent";
import { apiRoutes } from "../../../apis/routes";
import { getRequest, postRequest } from "../../../apis/methods";
import { useSelector,useDispatch } from "react-redux";
import { modalHandler } from "../../../store/slices/modalSlice";
import { useNavigate } from 'react-router-dom';
import { Image_URL } from "../../../utils/BaseUrl";
export const DonationCard = ({
  data,
  comp = "",onCartClick,cardLoader,onImageClick,
  customStyle = { height: "440px", width: "302px" },onClick
}) => {
  const [isHovered, setIsHovered] = useState(false); // State to track hover
 const[type,setType]=useState("")
  const [showInput, setShowInput] = useState(false); // State to toggle button/input
  const [isButtonGreen, setIsButtonGreen] = useState(false); 
  const language = useSelector((state) => state.language.value);
  const [price, setPrice] = useState(""); 
  const[addloading,setAddLoading]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDonateClick = (data) => {

    fetchData(data)
    setType("isDonate")
    setIsButtonGreen(true)
    setShowInput(true); // Show input when "Donate Now" button is clicked
  };
  const handleAddCart = (data) => {

    fetchData(data)
    setType("isCart")
    setIsButtonGreen(true)
    setShowInput(true); // Show input when "Donate Now" button is clicked
  };

  const fetchData = async () => {
 
    try {
      const response = await getRequest(`${apiRoutes.get_cart_price}?itemable_id=${data?.id}&itemable_type=${'project'}`); // Replace with your endpoint
if(response.success)
   { 
   
    setPrice(response?.data?.price)
    console.log("Results",response)
  }
  else{
    
  }
    } catch (error) {
    
      console.error('Error fetching data:', error);
    }
  };

  const handleCloseInput = () => {
    
    setIsButtonGreen(false)
    setShowInput(false); // Close input and show "Donate Now" button when cross is clicked
   setTimeout(()=>{
    setPrice('')
   },150)
    
  };

  const handlesubmit = async (item) => {
    console.log("New handle submit")

    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  
    const payload = {
      itemable_type: "project",
      itemable_id:data?.id,
  
      price: price  
    };
   
  if(price){
    setAddLoading(true)
if(type==="isDonate"){
  try {
    const response = await postRequest(apiRoutes.add_donate, payload); // Replace with your endpoint
    console.log("Response", response);
 
    if (response?.success) { 
      setAddLoading(false)
      setIsButtonGreen(false)
      setShowInput(false);
      dispatch(modalHandler(""))
      // dispatch(donationHandler("Donate"))
      // localStorage.setItem('Donate',true)
      navigate(`/checkout/donation`);
    
 
    } else {
      setAddLoading(false)
      // setIsAddModal(false)
      dispatch(modalHandler({ type: "toastError", msg: response?.data?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
     
     
    }
  } 
  catch (error) {
    console.error('Error fetching data:', error);
  }

}
else
  {  
  
    try {
      const response = await postRequest(apiRoutes.add_cart, payload); // Replace with your endpoint
      console.log("Response", response);
   
      if (response?.success) { 
        setAddLoading(false)
        setIsButtonGreen(false)
        setShowInput(false);
        dispatch(modalHandler({ type: "toast", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
    
   
      } else {
        setAddLoading(false)
        // setIsAddModal(false)
        dispatch(modalHandler({ type: "toastError", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
     
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  
  }
  
  }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("priceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
  
  };

  
  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);
   
  };

  return (
    <div
      className="bg-white relative border-borderColor border-[1.5px] rounded-[8px] cursor-pointer"
      style={customStyle}
      onClick={onImageClick}
    >
    <div
      onMouseEnter={() => setIsHovered(true)}  // Set hover state to true on hover
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when not hovering
    className={`${isHovered && 'relative group'}`} >
        {/* Image */}
        <img
          className="rounded-t-[8px] h-52 object-cover w-full"
          src={`${Image_URL}/${data?.image}`}
        />

        {/* Overlay with black blur and title (hidden by default) */}
        {isHovered && (
        <div
          className={`absolute inset-0 z-20 bg-black bg-opacity-50 opacity-0 ${isHovered && 'group-hover:opacity-100'}  flex justify-center items-center transition-opacity duration-300 rounded-t-[8px]`}
          style={{ backdropFilter: `blur(1px)` }}
        >
          <h1 className="text-white font-bold text-F16">
            {language === "_ar" ? data?.title_ar : data?.title}
          </h1>
        </div>)}
      </div>
      {/* <img className="rounded-t-[8px] h-52 object-cover" src={data?.image} /> */}
      {!isHovered && (
      <div
        className={`absolute space-x-1   rounded-[4px]  flex ${
          language === "_ar"
            ? "flex-row-reverse right-2 space-x-reverse"
            : "left-2"
        } items-center  px-2 py-1  top-2 z-10 text-tagColor text-F12  transition-opacity duration-300  ${"group-hover:opacity-0"}`}
        style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
      >
        <SvgComponent
          name={"Clock"}
          className={"w-3.5 h-3.5"}
          stroke={"#E94747"}
          fill={"none"}
        />
        <h1 className="font-semibold">{data?.ends_in} {GetAppText("day")}</h1>
      </div>)}
      <div className=" flex-col p-4 space-y-8 ">
        <div className=" space-y-4">
          <div
            className={`font-bold flex   ${
              language === "_ar" ? "flex-row-reverse " : ""
            } text-F16`}
          >
            { language === "_ar" ? data?.title_ar: data?.title}
          </div>
          <ProgressBar value={data?.goal_percentage} />

          <div className="space-y-1.5">
            <div
              className={`flex items-center justify-between text-F14 font-semibold ${
                language === "_ar" ? "flex-row-reverse " : ""
              }  `}
            >
              <div className=" ">{GetAppText("donated")}</div>
              <div className=" ">{GetAppText("goal")}</div>
            </div>
            <div
              className={`flex items-center justify-between text-F14 font-semibold ${
                language === "_ar" ? "flex-row-reverse " : ""
              }  `}
            >
              <div
                className={`flex  space-x-1 text-F14 text-secondaryTextColor ${
                  language === "_ar" ? "flex-row-reverse space-x-reverse" : ""
                }`}
              >
                <h1>{data?.donated_amount?.toLocaleString()}</h1>
                <div> {GetAppText("bhd")}</div>
              </div>
              <div
                className={`flex  space-x-1 text-F14 text-secondaryTextColor ${
                  language === "_ar" ? "flex-row-reverse space-x-reverse" : ""
                }`}
              >
                <h1>{data?.goal_amount?.toLocaleString()}</h1>
                <div> {GetAppText("bhd")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <ButtonComponent
          loading={addloading}
          disabled={addloading}
            styles={`px-2 ${addloading?"!py-2":"!py-2.5"}  ${isButtonGreen ? "bg-secondary !border-secondary" : "bg-primary"} transition-colors duration-500 `}
            // onClick={onCartClick}
            onClick={(e) => {e.stopPropagation();  !isButtonGreen ? handleAddCart():handlesubmit()}}
          tooltip={isButtonGreen && type==="isDonate" ? GetAppText("donate_now"):GetAppText("add_cart")}
            svg={
              <SvgComponent
                name={`${isButtonGreen ? "ArrowLeft" : "Bag"}`}
                className={"w-5 h-5"}
                stroke={"white"}
                fill={"none"}
              />
            }
       
            type="primary"
          />
        

          {/* <ButtonComponent
           onClick={onClick}
            isSvg
            styles={"text-F16 w-full   "}
            title={GetAppText(comp === "home" ? "click_here" : "donate_now")}
          /> */}
    <div className="relative w-full ">
            <div
              className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${
                showInput ? "opacity-0 pointer-events-none" : "opacity-100"
              }`}
            >
              <ButtonComponent
              tooltip={GetAppText("donate_now")}
                onClick={(e) => {e.stopPropagation();  handleDonateClick()}} // Show input when clicked
                isSvg
                styles={"text-F16 w-full !py-2"}
                title={GetAppText(comp === "home" ? "donate_now" : "donate_now")}
              />
            </div>

            <div
              className={`absolute inset-0 flex items-center transition-opacity duration-500 ${
                showInput ? "opacity-100" : "opacity-0 pointer-events-none"
              }`}
            >
              <input
                // className="w-full text-F16 border-[1.5px] border-borderColor rounded px-2 py-2.5 focus:border-none"
                className={`block w-full  py-[10.5px] placeholder:text-F14 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                  language === "_ar" ? "pe-2 text-right" : "ps-3"
                }`}
                onClick={(e)=>{e.stopPropagation(); }}
                type="number"
                value={price} // Controlled input value
                onChange={(e)=>{  e.stopPropagation();  handlePriceChange(e)}} // Update price on change
                // placeholder="Enter Amount"
                placeholder={GetAppText("donation_amount")}
              />
              <button
              title={GetAppText("cancel")}
                className={`absolute ${language === "_ar" ? "left-2" : "right-2"
                }   top-1/2 transform -translate-y-1/2 text-gray-400`}
                onClick={(e) =>{  e.stopPropagation();  handleCloseInput()}} // Close input, show button again
              >
                ✕
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
