import { useSelector } from "react-redux";
import en from "../utils/Language/en";
import ar from "../utils/Language/ar";
import { store } from "../store";

export const GetAppText = (key) => {
  const currentLang = store.getState().language.value;

  switch (currentLang) {
    case "_en":
      return en[key];
    case "_ar":
      return ar[key];
    default:
      return en[key];
  }
};

export const StyleClass = () => {
  const currentLang = store.getState().language.value;

  return currentLang === "_ar" ? "flex-row-reverse space-x-reverse" : "";
};

export const secondaryColor = "#E49E4E";
