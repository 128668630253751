export const apiRoutes = {
get_home: 'home',
  get_projects:"projects",
  get_projectDetails:"project",
  get_news:"news",
  get_stories:"stories",
  get_storyDetails:"story",
  get_newsDetails:"news",
  register:"register",
  login:"login",
  verify_otp:"verify/otp",
  logout:"logout",
  countries:"countries",
get_faqs:"faqs",
get_terms:"terms",
get_about:"about",
get_policy:"privacy",
get_cart:"cart",
add_cart:"cart/add",
delete_cart:"cart/item",
add_cart_price:"cart/project/add/price",
get_init:"init",
update_profile:"update/profile",
get_cart_price:"cart/item",
add_donate:"donation/add",
get_donate:"donation",
delete_donate:"donation/item"

}