
import React, { useEffect, useState } from "react";

import image from "../../../../assets/images/10.png"
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from "../../../../apis/routes";
import { getRequest } from "../../../../apis/methods";
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";
const StoriesListing = () => {
  const language = useSelector((state) => state.language.value);
  const [isHovered, setIsHovered] = useState(false); 
const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
        useEffect(()=>{
          fetchData()
        },[])

        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(apiRoutes.get_stories); // Replace with your endpoint
      if(response.success)
         { 
          setLoading(false)
          setData(response?.data?.stories)
          console.log("Results",response)
        }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
        };

const navigate = useNavigate();



const handleProjectClick = (id) => {
  console.log("ID",id)
  navigate(`/story/${id}`);
};

    return (
        <div>
             <div className="relative">
      <img src={image} className="h-[400px] object-cover w-full"  alt=""/>
      <div className="flex justify-between">
      <div
            className={`flex items-center  z-30 absolute bottom-10  ${language === "_ar"
                ? "flex-row-reverse right-14"
                : "left-14"
              } text-white text-F40 font-bold `}
          >{GetAppText("project_story")}</div>
          </div>
          <div
            className={`flex items-center  z-30 absolute bottom-10 ${language === "_ar"
                ? "flex-row-reverse left-14"
                : "right-14"
              } text-white text-F40 font-bold `}
          >{GetAppText("contribute")}</div>
       </div>
      <div className="px-20 py-12 w-full space-y-6">
        <div className="flex flex-wrap gap-6 justify-center items-center">
{/* {images&&images?.map((item)=>(
<img key={item?.id} src={item?.image} className="rounded-lg cursor-pointer" alt=""
   onClick={() => handleImageClick(item?.id)}
/>

))} */}
     { loading ?
         <div className="flex h-[250px] w-full justify-center items-center">
         <Loader/>
         </div>
         
         : 
        
         data?.length>0? 
         <div className="flex justify-center flex-wrap gap-6">
 {         data.map((item,index)=>
(
//   <div className={`h-[231px]  w-[410px] !rounded-lg `}>
//     <img  
//     src={`${Image_URL}/${item?.image}`}
  
//     className= {`h-full w-full rounded-lg cursor-pointer`} alt=""
//     onClick={() => handleProjectClick(item?.id)}
//  />
//   {/* {item?.media?.map((items,index)=>(
//   (  index === 0 &&  <img key={index?.id} src={items?.thumbnail} className= {`h-full w-full rounded-lg cursor-pointer`} alt=""
//     onClick={() => handleProjectClick(item?.id)}
//  />)
//   ))} */}
//   </div>
 <div
 key={index}
      onMouseEnter={() => setIsHovered(true)}  // Set hover state to true on hover
      onMouseLeave={() => setIsHovered(false)} // Set hover state to false when not hovering
    className={`${isHovered && 'relative group'} rounded-lg  font-semibold flex justify-center items-center cursor-pointer h-[231px] w-[410px]`} 
   
    onClick={() => handleProjectClick(item?.id)}
    >
        {/* Image */}
        <img
         className="rounded-lg" alt="logo"
          // className="rounded-t-[8px] h-52 object-cover w-full"
          src={`${Image_URL}/${item?.image}`}
        />

        {/* Overlay with black blur and title (hidden by default) */}
        {isHovered && (
        <div
          className={`absolute inset-0 z-20 bg-black bg-opacity-50 opacity-0 ${isHovered && 'group-hover:opacity-100'}  flex justify-center items-center transition-opacity duration-300 rounded-t-[8px]`}
          style={{ backdropFilter: `blur(1px)` }}
        >
          <h1 className="text-white font-bold text-F20">
            {language === "_ar" ? item?.title_ar : item?.title}
          </h1>
        </div>)}
      </div>
//   <div key={item?.id} className="rounded-lg border border-borderColor font-semibold flex justify-center items-center cursor-pointer h-[231px] w-[410px]" 
//    onClick={() => handleProjectClick(item?.id)}>
//     {language === "_ar"?item?.title_ar:item?.title}
// </div>

))  }
</div>
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
          {GetAppText("no_data")}
              </div>)}
</div>

      </div>
      </div>
    );
  };
  
  export default StoriesListing;
  