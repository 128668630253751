import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";
import { AccordionItem } from "../../../components/Accordian";

import { getRequest } from "../../../../apis/methods";
import { apiRoutes } from "../../../../apis/routes";
import { Loader } from "../../../components/Loader";

const Faqs = () => {
  const language = useSelector((state) => state.language.value);

  const [faqloading, setfaqLoading] = useState(false);
  const [faqdata, setFaqData] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const fetchData = async () => {
    setfaqLoading(true);
    try {
      const response = await getRequest(`${apiRoutes.get_faqs}`); // Replace with your endpoint
      if (response.success) {
        setfaqLoading(false);
        setFaqData(response?.data?.faqs);

        console.log("Results", response);
      } else {
        setfaqLoading(false);
      }
    } catch (error) {
      setfaqLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className={`w-full space-y-6`}>
      <h2
        className={`flex items-center ${
          language === "_ar" ? "flex-row-reverse  space-x-reverse" : ""
        } text-primary text-F24 font-bold `}
      >
        {GetAppText("frequently_asked_questions")}
      </h2>

      {faqloading ? (
        <div className="flex h-[440px] w-full justify-center items-center">
          <Loader />
        </div>
      ) : (
        faqdata &&
        faqdata?.map((items, index) => {
          const keyName = Object.keys(items)[0];
          return (
            <div
              key={index}
              className="w-full bg-offWhite p-6 rounded-xl space-y-7"
            >
              <h2
                className={`flex   items-center ${
                  language === "_ar" ? "flex-row-reverse  space-x-reverse" : ""
                } text-secondary text-F20 font-bold `}
              >
                {keyName}
              </h2>
              <div className="space-y-5">
                {items[keyName].map((item, index) => (
                  <AccordionItem
                    key={index}
                    index={index}
                    title={
                      language === "_ar" ? item.question_ar : item.question
                    }
                    content={language === "_ar" ? item.answer_ar : item.answer}
                    isOpen={openIndex === index}
                    onToggle={toggleAccordion}
                  />
                ))}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Faqs;
