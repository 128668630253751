import React, { useEffect, useRef, useState } from "react";
import { GetAppText, StyleClass } from "../../../../utils";
import SvgComponent from "../../../components/SvgComponent";
import { useSelector,useDispatch } from "react-redux";
import { DonationCard } from "../../../components/DonationCard";
import { useNavigate } from 'react-router-dom';

import { Loader } from "../../../components/Loader";
import DonationModal from "../../../components/DonationModal";
import { modalHandler } from "../../../../store/slices/modalSlice";
export const ProjectsEndingSoon = ({data,loading}) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const[details,setDetails]=useState('')
  const [isAddModal, setIsAddModal] = useState(false);
  const dispatch = useDispatch();
//   const [loading,setLoading]=useState(false)
// const[data,setData]=useState('')
  const language = useSelector((state) => state.language.value);
  const navigate = useNavigate();
  const scrollRef = useRef(null);



  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

      if (language === "_ar") {
        const maxScrollLeft = -(scrollWidth - clientWidth);
        setCanScrollLeft(scrollLeft > maxScrollLeft);
        setCanScrollRight(scrollLeft < 0);
      } else {
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    }
  };

  useEffect(() => {
    checkScrollPosition(); // Check the scroll position initially
  
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", checkScrollPosition);
    }
  
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, [data, language]);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: "smooth" });
      
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };


  const ImageClick = (val) => {
   
    navigate(`/donation-project-details/${val?.id}`);
  };
  const handleProjectClick = (val) => {
    setDetails(val)
  
    setIsAddModal(true)
  dispatch(modalHandler("isDonate"));
  
  };
  const handleCartDetails=(items)=>{
    console.log("Data",items)
    setDetails(items)
    setIsAddModal(true)
    dispatch(modalHandler(""));
    
    }

  return (
    <div className="w-full relative">
      <div
        className={`absolute top-0 ${
          language === "_ar" ? "right-0" : "left-0"
        }`}
      >
        <img
          src={require(`../../../../assets/images/${
            language === "_ar" ? "Vector2" : "Vector4"
          }.png`)}
          alt="logo"
        />
      </div>
      <div
        className={`sm:flex items-center sm:justify-center w-full h-full py-6 sm:py-20 ${
          language === "_en" ? "" : "sm:flex-row-reverse"
        }`}
      >
          <div className=" h-full grid justify-items-stretch">
          <div className={`h-full  sm:px-0 px-4 sm:pb-0 pb-7
             ${
          language === "_ar" ? "sm:justify-self-center justify-self-end " : "sm:justify-self-center "
        }
            sm:justify-self-center  md:pt-4 sm:pl-4 sm:space-y-12 space-y-2  pt-4 sm:w-9/12 w-full`}>
            <div className="space-y-2">
              <h1 className={`text-black ${
                  language === "_en" ? "text-left" : "text-right"
                } sm:text-F32 text-F22 font-semibold `}>
                {GetAppText("project_ending_soon")}
              </h1>
              <h1 className={`text-black sm:text-F18 text-F14  ${
                  language === "_en" ? "text-left" : "text-right"
                }`}>
                {GetAppText("contribute_to_these_projects")}
              </h1>
            </div>
            <div
              className={`sm:pt-6 flex items-center flex-wrap justify-between ${StyleClass()}`}
            >
              <div
                className={`flex items-center space-x-2 ${
                  language === "_en" ? "flex-row-reverse space-x-reverse" : ""
                }`}
              >
                <SvgComponent
                  name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                  className={"w-6 h-6"}
                  fill="none"
                  stroke={"black"}
                />
                <span className="text-F18 whitespace-nowrap hover:cursor-pointer hover:underline font-semibold">
                  {GetAppText("show_more")}
                </span>
              </div>
              <div className="flex flex-row items-center sm:pt-0 pt-3 space-x-2">
                <div
                  onClick={scrollLeft}
                  className={`flex justify-center items-center p-2 border-2 border-black rounded-full ${
                    !canScrollLeft
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer"
                  }`}
                  disabled={!canScrollLeft}
                >
                  <SvgComponent
                    name={"ArrowLeft"}
                    className={"w-6 h-6"}
                    stroke={"black"}
                    fill={"none"}
                  />
                </div>
                <div
                  onClick={scrollRight}
                  className={`flex justify-center items-center p-2 border-2 border-black rounded-full  
                    ${
                    !canScrollRight
                      ? "opacity-50 cursor-not-allowed"
                      : "cursor-pointer"
                  }
                  `}
                  disabled={!canScrollRight}
                >
                  <SvgComponent
                    name={"ArrowRight"}
                    className={"w-6 h-6"}
                    stroke={"black"}
                    fill={"none"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-[68%] pl-2 w-full  h-full">
          <div
            className={`flex w-full space-x-6 overflow-x-auto scrollbar-hide  ${
              language === "_ar"
                ? "flex-row-reverse space-x-reverse pl-10"
                : "pr-10"
            }`}
            ref={scrollRef}
          >

{ loading ?
         <div className="flex  w-full justify-center items-center">
         <Loader/>
         </div>
         : data?.length>0? 
          data.map((item,index)=>
(
<div key={item?.id} className="min-w-[302px]">
<DonationCard comp={"home"}   onCartClick={()=>{handleCartDetails(item)}} onImageClick={()=>{ImageClick(item)}}  onClick={()=>{handleProjectClick(item)}} key={index}  data={item}/>
  </div>))  
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
              {GetAppText("no_data")}
              </div>)}

         {/* { donations?.map((items)=>(<div key={items?.id} className="min-w-[302px]">
              <DonationCard comp={"home"} customStyle={{}} data={items} />
            </div>))  } */}
           
          </div>
        </div>
      
       {isAddModal&& <DonationModal isAddModal={isAddModal} details={details} setIsAddModal={setIsAddModal}/>}
      </div>
    </div>
  );
};
