export default {
  login: "Login",
  email: "Email",
  password: "Password",
  forgotPassword: "Forgot Password?",
  createNewAccount: "Create New Account",
  home: "Home",
  donationProjects: "Donation Projects",
  about_us: "About us",
  technical_support: "Technical Support",
  tagLine: "The official donation portal for Sunni Endowments",
  download_app: "Download the application",
  donation: "Donation",
  donation_basket: "Donation basket",
  my_account: "My account",
  all_rights: "Do good. All rights reserved",
  terms_use: "Terms of use",
  privacy_policy: "Privacy policy",
  official_donation_portal: "The official donation portal for Sunni Endowments",
  search_here: "Search here",
  days: "12 days left",
  school: "Building a school in rural areas",
  donated: "Donated",
  goal: "The goal",
  bhd: "BHD",
  donate_now: "Donate now",
  stride_to_achieve: "What we strive to achieve",
  achievement_desc:
    "We seek to improve the lives of individuals and communities in need by implementing sustainable charitable projects and initiatives. We believe in the importance of education, health care, and social solidarity to build a better future.",
  donation_type: "Type",
  education: "Health & Education",
  condition: "Status",
  sort: "Sort by",
  closest_complete: "Closet to goal",
  donation_project: "Donation Projects",
  beneficiary: "Beneficiaries",
  donation_process: "Donation Transactions",
  sharing: "Share",
  urgent: "Urgent",
  zakat: "Zakah Compliant",
  close_target: "Close to goal",
  remain_time: "Remaining time",
  domain: "Domain",
  educational: "Educational",
  max_limit: "Maximum donation limit",
  residual: "Remaining",
  add_cart: "Add to cart",
  similar_projects: "Similar projects",
  create_account: "Create an account",
  project: "Project",
  donation_value: "Donation value",
  total: "Total",
  complete_donation: "Complete the donation process",
  day: "Day",
  payment_page: "Payment page",
  donation_summary: "Summary of the donation process",
  edit: "Edit",
  payment_method: "Payment method",
  debit_card: "Debit card",
  card_info: "Card information",
  card_name: "Name on the card",
  card_number: "Card number",
  expiry_date: "Expiry date",
  security_code: "Security code",
  continue: "By continuing",
  move_pay: "Move to pay",
  project_story: "Every project has a story",
  contribute: "Contribute to writing it",
  other_story: "Other stories",
  all_stories: "All Stories",
  read_more: "Read More",
  news: "News",
  project_ending_soon: "Project ending soon",
  contribute_to_these_projects:
    "Contribute to these projects before the donation period ends, and help us achieve goals and support communities in need.",
  show_more: "Show More",
  click_here: "Click here",
  urgent_projects: "Urgent Projects",
  building_schools: "Building Schools in Rural Areas",
  details: "Details",
  days_left: "12 days left",
  overview: "An Overview of the organization and its mission",
  donation_amount: "Donation Amount (BD)",
  name: "Name",
  nationality: "Nationality",
  phone: "Phone number",
  personal_number: "Personal number",
  confirm_password: "Confirm password",
  password_valid_msg:
    "At least 8 characters, at least one uppercase letter and at least one lowercase letter.",
  complete_registration: "Complete the registration",
  have_an_account: "Do you have an account? Log in",
  learn_more: "Learn More",
  donate: "Donate",
  hope_grows: "Hope grows in the rural areas",
  donation_successful: "The donation was successful",
  payment_failed: "Payment failed",
  donation_success_msg:
    "Thank you. The donation process has been completed successfully. We ask God to accept this charity from you and place it in the balance of your good deeds.",
  donation_failed_msg:
    "Unfortunately, the donation process has not been completed. Check your payment information or contact your bank. Please try again later",
  back_to_main: "Back to main page",
  share_project: "Share the project",
  retry: "Retry",
  personal_information: "Personal Information",
  account_settings: "Account Settings",
  donation_record: "Donation Record",
  sunni_endowments: "Sunni Endowments",
  about_sunni_endowments: "About Sunni Endowments",
  terms_use_conditions: "Terms of use and conditions",
  privacy_policy: "Privacy Policy",
  help_support: "Help and Support",
  frequently_asked_questions: "Frequently Asked Questions",
  contact_us: "Contact us",
  contact_desc:
    "Access support resources and contact information for assistance.",
  logout: "Logout",
  download_app_now: "Download the application now",
  donate_easily_quickly: "Donate easily and quickly through your mobile phone",
  test_user_name: "Kareem Abdullah",
  our_website: "Our Website",
  address:
    "Sunni Endowments Directorate, 2nd Floor, Building 46, Road 3701, Block 337, Manama, Bahrain",
  get_direction: "Get direction",
  nationality: "Nationality",
  phone_number: "Phone Number",
  personal_number_CPR: "Personal Number (CPR)",
  save_changes: "Save Changes",
  option: "Option",
  calculating: "Calculating Direction ....",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_new_password: "Confirm new password",
  delete_account: "Delete Account",
  donation_date: "Donation Date",
  project_name: "Project Name",
  delete_account_note:
    "Please note that deleting your account will permanently remove all your data and cannot be undone. If you're sure you want to continue, click the delete button below.",
  project_status: "Project Status",
  complete: "Complete",
  pending: "Pending",
  transaction_number: "Transaction Number",change_password:"Change Password",
  enter_otp:"Enter the OTP verification code",verify_code:"A verification number has been sent to your email",sure:"Sure",
  resend_code:"Resend the code",resend_after:"Resend the code later",
  enter_password:"Enter the new password",
  no_data:"No Data Found ...",fields_req:"All fields are required",loading:"Loading",logout_msg:"Are you sure you want to log out?",cancel:"Cancel",
  project_add:"Project added to",donation_bag:"Donation Bag",delete_msg:"Are you sure you want to delete this?",delete:"Delete",Other_news:"Other News",
  instant_donate:"Instant donation",select_category:"Select category",choose_amount:"Choose the amount",link:"The link has been copied",
  link_share:"Share link", language:"Change language"
};
