// axiosInterceptor.js
import axios from 'axios';
import { BASE_URL } from '../../utils/BaseUrl';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// Create an instance of Axios


const getDeviceFingerPrint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  return result.visitorId;
};

const getDeviceInfo = async () => {
  const nAgt = navigator.userAgent;


  // Sample browser/device detection functions
const getBrowserName = () => {
  const edgeRegex = /Edg\/([\d\.]+)/; // Updated regex to detect Edge
  const browsers = {
    Edge: edgeRegex, // Edge detection first
    Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
    Safari: /Version\/([\d\.]+).*Safari/,
    Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
    IE: /MSIE ([\d\.]+).*Trident/,
    Opera: /(?:Opera|OPR)\/([\d\.]+)/,
  };

  for (const browserName in browsers) {
    const match = nAgt.match(browsers[browserName]);
    if (match) {
      return browserName;
    }
  }
  return 'Unknown';
};

const getDeviceType = () => {
  const isMobileUA = /Mobi|Android/i.test(nAgt);
  const isTabletUA = /Tablet|iPad/i.test(nAgt);
  const isDesktopUA = /Windows NT|Macintosh/i.test(nAgt);

  const screenWidth = window.screen.width;
  const isSmallScreen = screenWidth <= 768;

  if (isDesktopUA && !isSmallScreen) {
    return "Desktop";
  } else if (isTabletUA) {
    return "Tablet";
  } else if (isMobileUA || isSmallScreen) {
    return "Mobile";
  } else {
    return "Unknown";
  }
};

  const fingerPrintId = await getDeviceFingerPrint();

  return {
    user_agent: nAgt,
    device_width: window.screen.width,
    device_height: window.screen.height,
    browser_name: getBrowserName(),
    device_type: getDeviceType(),
    fingerprint_id: fingerPrintId,
  };
};
const info = await getDeviceInfo();


const axiosInstance = axios.create({
  baseURL: BASE_URL, // Replace with your API base URL
});

// Add request interceptor for attaching token
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from localStorage or any storage
    const token = localStorage.getItem('token');

   
    if (token) {
      // Attach token to Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    if (info?.fingerprint_id) {
      config.headers['X-Device-Id'] = info?.fingerprint_id;
    }


    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add response interceptor for token validation
axiosInstance.interceptors.response.use(

  (response) => {
    console.log("Response",response)
    // Return the response data if token is valid
    return response;
  },
  (error) => {
    // Handle response errors like token expiration or invalid token
    if (error.response && error.response.status === 401) {
      console.log('Token expired or invalid');
      localStorage.removeItem('token'); // Optionally remove the token
      window.location.href = '/'; // Redirect to login page
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
