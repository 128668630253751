import React from "react";
import { GetAppText } from "../../utils";
import logo from "../../assets/images/footerLogo.png";
import facebook from "../../assets/svgs/facebook.svg";
import linkdin from "../../assets/svgs/linkdin.svg";
import tweeter from "../../assets/svgs/tweeter.svg";
import insta from "../../assets/svgs/instagram.svg";
import { ReactComponent as IOS } from "../../assets/svgs/apple.svg";
import { ReactComponent as Android } from "../../assets/svgs/android.svg";
import { ReactComponent as Gallery } from "../../assets/svgs/gallery.svg";
import { ReactComponent as Logo } from "../../assets/svgs/footerLogo.svg";
import { useSelector } from "react-redux";
import { SeparatorComponent } from "./Separator";
import { NavLink } from "react-router-dom";

function Footer() {
  const img = [
    { id: 1, name: insta },
    { id: 2, name: tweeter },
    { id: 3, name: linkdin },
    { id: 4, name: facebook },
  ];

  const language = useSelector((state) => state.language.value);

  const app = [
    { id: 1, name: "App Gallery", img: <Gallery /> },
    { id: 2, name: "Android", img: <Android /> },
    { id: 3, name: "IOS", img: <IOS /> },
  ];

  return (
    <footer className="bg-primary shadow dark:bg-gray-900">
      <div className="w-full   p-4 lg:px-32 md:px-16 px-12  sm:pt-16 pt-10  md:pb-12">
        <div
          className={`sm:flex  ${
            language === "_ar" ? "flex-row-reverse" : ""
          }   sm:justify-between`}
        >
          <div className="sm:space-y-6 space-y-3">
          <NavLink to={"/"}>
            <a
             
              className={`flex  ${
                language === "_ar"
                  ? "flex-row-reverse space-x-3"
                  : " space-x-reverse"
              }  items-center  rtl:space-x-reverse`}
            >
              <Logo/>
            
            </a>
            </NavLink>
            <div className={`text-lightTextColor ${
                language === "_ar" ? "flex-row-reverse" : ""
              } flex  text-F16`}>
              {GetAppText("tagLine")}
            </div>
          </div>

          <div className="space-y-2.5 sm:pt-0 pt-5 h-full ">
            <div
              className={`text-white flex  ${
                language === "_ar" ? "flex-row-reverse" : ""
              }  text-F16`}
            >
              {GetAppText("download_app")}
            </div>
            <div className={`flex  ${
                language === "_ar" ? "justify-end" : ""
              } `}>
              <div className={`flex ${
                language === "_ar" ? "justify-end" : ""
              }  flex-wrap gap-3`}>
                {app &&
                  app?.map((item) => (
                    <div
                      key={item?.id}
                      className="flex items-center space-x-3 text-F16 text-white px-4 py-1.5 border-white rounded-md border-2 "
                    >
                      <div>{item.img}</div>
                      <div>{item.name}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between  sm:items-center  pt-7">
          <ul className="flex  sm:gap-8 gap-3 flex-wrap sm:items-center w-full  text-F16 font-normal text-white sm:mb-0 dark:text-gray-400">
          <NavLink to={"/privacy-policy"}>
          <li>
              <a  className="hover:underline">
                {GetAppText("privacy_policy")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/terms-condition"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("terms_use")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/about-us"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("about_us")}
              </a>
            </li>
            </NavLink>
          </ul>
          <ul
            className={`flex flex-wrap sm:items-center text-F16  ${
              language === "_ar" ? "flex-row-reverse space-x-reverse" : ""
            }   font-normal text-white sm:mb-0 w-full sm:gap-6 gap-3 dark:text-gray-400`}
          >
                  <NavLink to={"/"}>
            <li>
              <a  className="hover:underline  ">
                {GetAppText("home")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/donation-projects"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("donation")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/technical-support"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("technical_support")}
              </a>
            </li>
            </NavLink>
            <NavLink to={"/donars-bag"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("donation_basket")}
              </a>
            </li>
            </NavLink>
            {/* <NavLink to={"/profile"}>
            <li>
              <a  className="hover:underline ">
                {GetAppText("my_account")}
              </a>
            </li>
            </NavLink> */}
          </ul>
        </div>
     <SeparatorComponent />
        <div className="flex flex-wrap sm:gap-0 gap-4 justify-between items-center">
          <div className="flex gap-6">
            {img &&
              img?.map((item) => (
                <img
                  key={item?.id}
                  src={item.name}
                  className="h-6 w-6"
                  alt="Logo"
                />
              ))}
          </div>

          <div
            className={`sm:text-center  ${
              language === "_ar" ? "" : "flex-row-reverse space-x-reverse"
            } text-lightTextColor flex space-x-1 text-F16 dark:text-gray-400`}
          >
            <div>{GetAppText("all_rights")} </div>
            <span
              className={`flex ${language === "_ar" ? "" : "flex-row-reverse"}`}
            >
              <div>{new Date().getFullYear()}</div> <div>©</div>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
