import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { StyleClass } from "../../../../../utils";
import { InputComponent } from "../../../../components/InputComponent";
import { ButtonComponent } from "../../../../components/ButtonComponent";
import Dropdown from "../../../../components/Dropdown";
import SvgComponent from "../../../../components/SvgComponent";
import { postRequest } from "../../../../../apis/methods";
import { apiRoutes } from "../../../../../apis/routes";
import { modalHandler } from "../../../../../store/slices/modalSlice";

export const PersonalInformation = ({data,countrydata}) => {
  useEffect(()=>{
    console.log("Dataaa13",data)
    setName(data?.name)
    setSelectedValue(data?.phone_code) 
    setPhoneNumber(data?.phone_number)
    setEmail(data?.email)
    setPersonal_CPR(data?.cpr_number)
    setSelectedValue2(data?.country)
  },[])
  const [name, setName] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [personal_CPR, setPersonal_CPR] = useState("");
  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const language = useSelector((state) => state.language.value);
  const textAlignStyle = language === "_ar" ? "right" : "left";
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const[loading,setLoading]=useState(false)
const dispatch = useDispatch();

  const handler=()=>{
    console.log("helooo")
    setIsDropdownOpen(!isDropdownOpen);
  }

  const handleSelect = (value) => {
    setSelectedValue(value?.phone_code);
    setIsDropdownOpen(false);
  };


  const handleSubmit = async() => {
    const data={
    
      name: name,
      country: selectedValue2,
      email: email,
      phone_code: selectedValue,
      phone_number: phoneNumber,
      cpr_number:personal_CPR
    
    }
    setLoading(true)
    if(name && email && personal_CPR && selectedValue && phoneNumber && selectedValue2)
    {
      try {
      const response = await postRequest(apiRoutes.update_profile,data); // Replace with your endpoint
    if(response.success)
    { 
    setLoading(false)

    dispatch(modalHandler({ type: "updateToast", msg: response?.message}));
    setTimeout(()=>{
        dispatch(modalHandler(""));
      },[500])
    }
    else{
     
      dispatch(modalHandler({ type: "updateToastError", msg: response?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
    setLoading(false)
    }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
    }
    else{
      setLoading(false)
      dispatch(modalHandler("Error"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
        console.log("Final Payload:", data);
        // Here, you can send the payload to an API or perform any other action needed.
      };



  const handler3=()=>{
    console.log("helooo")
    setIsDropdownOpen3(!isDropdownOpen3);
  }

  const handleSelect2 = (value) => {
    setSelectedValue2(value?.name);
    setIsDropdownOpen3(false);
  };
  return (
    <div className="w-full h-full">
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("personal_information")}
      </h1>
      <div className="pt-6">
        <div className="bg-white p-6 rounded-xl">
        <h1
        className={`text-secondaryTextColor pb-7 text-F18 font-semibold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("personal_information")}
      </h1>
          <div className="space-y-6">
            <div className={`flex items-center space-x-4 ${StyleClass()}`}>
              <div className="space-y-2 w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("name")}
                </span>
                <InputComponent
                  input_style={"!py-3"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="space-y-2 w-full">
                <span
                  className="text-black text-F14 block "
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("nationality")}
                </span>
                <div className="w-full">
            <ButtonComponent onClick={handler3}  title={selectedValue2===""?GetAppText("closest_complete"):selectedValue2}     
            svg={ <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-2.5`}
            stroke={"#1B1C1E"}
            fill={"none"}
          />} 
           styles={"py-3 w-full flex !border-2 !border-borderColor !rounded-lg justify-between px-4 text-sm whitespace-nowrap overflow-hidden text-ellipsis"} type="secondary_transparent"/>
            <Dropdown mainStyle={`w-full  !h-[200px]`}  handleSelect={handleSelect2}  isOpen={isDropdownOpen3} options={countrydata} setIsDropdownOpen={setIsDropdownOpen3} />
            </div>
             
              </div>
            </div>
            <div className={`flex items-center  space-x-4 ${StyleClass()}`}>
              <div className="space-y-2 relative w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("phone_number")}
                </span>
                <div>
<div className="flex items-center  py-1  border-2 border-borderColor  rounded-lg ">
  <div onClick={handler} className="flex cursor-pointer items-center px-2.5 space-x-2 border-e border-e-borderColor">
    <div className="text-sm">
      {selectedValue}
    </div>
  <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-1.5`}
            stroke={"black"}
            fill={"none"}
          />
  </div>
                <input
          type={"number"}
          id={"phone"}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          className={`block no-spinner w-full border-none focus:border-borderColor focus:outline-none focus:ring-0  placeholder:text-F14 text-sm placeholder:text-lightTextColor ${
            language === "_ar" ? "pe-2 text-right" : "ps-3"
          } `}
         
          name={"phone"}
        />
              
        </div>
        {isDropdownOpen&& <Dropdown phone={true} mainStyle={`w-full !h-[200px]`}  handleSelect={handleSelect}  isOpen={isDropdownOpen} options={countrydata} setIsDropdownOpen={setIsDropdownOpen} />}
      
        </div>
              </div>
              <div className="space-y-2 w-full">
                <span
                  className="text-black text-F14 block"
                  style={{
                    textAlign: textAlignStyle,
                  }}
                >
                  {GetAppText("email")}
                </span>
                <InputComponent
                  input_style={"!py-3"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div
              className={`flex w-full ${
                language === "_ar" ? "justify-end" : ""
              }`}
            >
              <div className={`w-6/12 ${language === "_ar" ? "pl-2" : "pr-2"}`}>
                <div className="space-y-2 w-full">
                  <span
                    className="text-black text-F14 block"
                    style={{
                      textAlign: textAlignStyle,
                    }}
                  >
                    {GetAppText("personal_number_CPR")}
                  </span>
                  <InputComponent
                    input_style={"!py-3"}
                    value={personal_CPR}
                    onChange={(e) => setPersonal_CPR(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex w-full ${language === "_en" ? "justify-end" : ""}`}
          >
            <div>
              <ButtonComponent
              onClick={handleSubmit}
              loading={loading}
              disabled={loading}
                type="secondary"
                title={GetAppText("save_changes")}
                styles={"!text-F18 !px-6"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
