import React, { useEffect, useState } from "react";
import { DonationCard } from "../../components/DonationCard";
import { GetAppText } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { ButtonComponent } from "../../components/ButtonComponent";
import {useLocation,useNavigate } from 'react-router-dom';
import Dropdown from "../../components/Dropdown";

import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { getRequest} from '../../../apis/methods';
import { apiRoutes } from "../../../apis/routes";
import { Loader } from "../../components/Loader";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { modalHandler } from "../../../store/slices/modalSlice";
import DonationModal from "../../components/DonationModal";
import { handleClick, handleSuccess } from '../../components/Toast/toastHelper';

const DonationProjects = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState('');
  const [selectedValueCondition, setSelectedValueCondition] = useState("");
const [loading,setLoading]=useState(false)
const dispatch = useDispatch();

const[data,setData]=useState('')
const[details,setDetails]=useState('')
const[category,setCategory]=useState('')
const[isButton,setIsButton]=useState(false)
const[type,setType]=useState('')
const[sort,setSort]=useState('')
const[searchParam,setSearchParam]=useState('')
const[categoryParams,setCategoryParams]=useState('')
const[statusParam,setStatusParam]=useState('')
const[sortParam,setSortParam]=useState('')
const navigate = useNavigate();
const [loadingStates, setLoadingStates] = useState({});
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);
useEffect(()=>{
  fetchData()
},[categoryParams,statusParam,sortParam,searchParam])
useEffect(()=>{
  if(isOpen?.type==="toast"){
    handleSuccess(isOpen?.msg,language)
  }
  else if(isOpen?.type==="toastError"){
    handleClick(isOpen?.msg,language)
  }
  else if(isOpen==="priceError"){
    handleClick("Price is required",language)
  }
},[isOpen])
const location = useLocation();
  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_projects}/search?search=${searchParam}&category_id=${categoryParams}&status=${statusParam}&sort_by=${sortParam}`
        ); // Replace with your endpoint
if(response.success)
   { 
    setLoading(false)
    setData(response?.data?.projects)
    setCategory(response?.data?.categories)
    setType(response?.data?.types)
    setSort(response?.data?.sortBy)
    console.log("Results",response)
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };
const handler=()=>{
  console.log("helooo")
  setIsDropdownOpen(!isDropdownOpen);
}
const handler2=()=>{
  console.log("helooo")
  setIsDropdownOpen2(!isDropdownOpen2);
}
const handler3=()=>{
  console.log("helooo")
  setIsDropdownOpen3(!isDropdownOpen);
}

const getQueryParams = () => new URLSearchParams(location.search);
const handleProjectClick = (val) => {
  setDetails(val)

  setIsAddModal(true)
dispatch(modalHandler("isDonate"));

};
const updateQueryParams = (newParams) => {
  const params = getQueryParams();
  Object.keys(newParams).forEach(key => {
    params.set(key, newParams[key]);
  });
  return params.toString();
};

const handleSelect = (value) => {
  // const newParams = updateQueryParams({ type: value });
  // navigate(`?${newParams}`);
  setStatusParam(value?.value)
  if(language === "_ar"){
    setSelectedValue(value?.key_ar)
  }
  else{
  setSelectedValue(value?.key);}
  setIsDropdownOpen(false);
};
const [selectedValues, setSelectedValues] = useState([]);
// const handleSelectCondition = (value) => {
//   setIsButton(true)
//   console.log("VALUE",value)
//   setCategoryParams(value?.id)
//   if(language === "_ar"){
//     setSelectedValueCondition(value?.title_ar)
//   }
//   else
//  { setSelectedValueCondition(value?.title)}
//   setIsDropdownOpen2(false);
// };
const handleSelectCondition = (value) => {
  // Update selectedValues with selected/unselected options
  setSelectedValues((prevSelected) => {
    const isSelected = prevSelected.some((item) => item.id === value.id);

    // Update selected IDs array
    setCategoryParams((prevIds) => {
      if (isSelected) {
        // Remove unselected option's ID from categoryParams array
        return prevIds.filter((id) => id !== value.id);
      } else {
        // Add selected option's ID to categoryParams array
        return [...prevIds, value.id];
      }
    });

    // Return updated selected values
    if (isSelected) {
      // Unselect the option
      return prevSelected.filter((item) => item.id !== value.id);
    } else {
      // Select the option
      return [...prevSelected, value];
    }
  });
  
  console.log("Selected IDs:", categoryParams);
};
const handleSelect2 = (value) => {
  setSortParam(value?.value)
  if(language === "_ar"){
    setSelectedValue2(value?.key_ar)
  }
  else{
  setSelectedValue2(value?.key);}
 
  setIsDropdownOpen3(false);
};

const handleCartDetails=(items)=>{
console.log("Data",items)
setDetails(items)
setIsAddModal(true)
dispatch(modalHandler(""));

}

const ImageClick = (val) => {
   
  navigate(`/donation-project-details/${val?.id}`);
};

  return (
    <>
  <div className={`flex ${language === "_ar" ? "flex-row-reverse " : ""} md:px-20 px-8 md:py-16 py-8 w-full`}>
    <div className="space-y-6 w-full">
    <ToastContainer />
 <h1 className={`flex ${language === "_ar" ? "flex-row-reverse " : ""} font-bold text-F24`}>

    {GetAppText("donationProjects")}
    </h1> 
     {/*Page header*/}
    <div
            className={`flex items-center ${
              language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
            } space-x-4 w-full`}
          >
  <div className="lg:w-4/12  w-11/12">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div
                  className={`absolute inset-y-0 flex items-center pointer-events-none ${
                    language === "_ar" ? "end-0 pe-3" : "start-0 ps-3"
                  }`}
                >
       <SvgComponent
              name={"Search"}
              className={`w-4 h-4`}
              stroke={"#727272"}
              fill={"none"}
            />
                </div>
                <input
                  type="search"
                  value={searchParam}  onChange={(e) => setSearchParam(e.target.value)}
                  id="default-search"
                  className={`block w-full p-2 py-3 placeholder:text-F12 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                    language === "_ar" ? "pe-5 text-right" : "ps-10"
                  }`}
                  placeholder={GetAppText("search_here")}

                />
              </div>
            </div>
            <div className="bg-borderColor  h-10" style={{width:"1.5px"}}></div>
            <div className={`flex   flex-wrap gap-3 items-end ${
              language === "_ar"
                ? ""
                : "lg:flex-row-reverse"
            }  justify-between w-full`}>
            <div className={`lg:flex  lg:space-y-0 space-y-1 items-center ${
              language === "_ar"
                ? "flex-row-reverse md:space-x-reverse"
                : "flex-row"
            }    md:gap-3 `}>
              <div className="text-F16 text-primary whitespace-nowrap">{GetAppText("sort")}</div>
              <div>
            <ButtonComponent onClick={handler3}  title={selectedValue2===""?GetAppText("closest_complete"):selectedValue2}     
            svg={ <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-2`}
            stroke={"#1B1C1E"}
            fill={"none"}
          />} 
           styles={"py-2 px-4 text-F16 whitespace-nowrap overflow-hidden text-ellipsis"} type="secondary_transparent"/>
            <Dropdown mainStyle={`!w-40`} sort={true}  handleSelect={handleSelect2}  isOpen={isDropdownOpen3} options={sort} setIsDropdownOpen={setIsDropdownOpen3} />
            </div>
            </div>
<div className={`flex  flex-wrap items-center gap-3  ${
              language === "_ar"
                ? "text-right"
                : "flex-row"
            }    `}>
              <div>
            <ButtonComponent onClick={handler}
             title={selectedValue===""?GetAppText("donation_type"):selectedValue}  
           
             svg={ <SvgComponent
              name={"ChevronDown"}
              className={`w-2.5 h-2`}
              stroke={"#1B1C1E"}
              fill={"none"}
            />} styles={"py-2 px-4 whitespace-nowrap   text-F16 "} type="secondary_transparent"/>
            <Dropdown sort={true} mainStyle={"!w-32"}  handleSelect={handleSelect}  isOpen={isDropdownOpen} options={type} setIsDropdownOpen={setIsDropdownOpen} />
            </div>
      {  isButton&&    <ButtonComponent onClickSvg={()=>{setIsButton(false);setSelectedValueCondition("");setCategoryParams('')}} title={selectedValueCondition} 
        svg={ <SvgComponent
          name={"Cross"}
          className={`w-2 h-2`}
          stroke={"white"}
          fill={"none"}
        />} 
            styles={"py-2 px-4 whitespace-nowrap   text-F16"} type="primary"/>}
          <div>
            <ButtonComponent onClick={handler2}
             title={selectedValueCondition===""?GetAppText("condition"):selectedValueCondition}  
          svg={ <SvgComponent
            name={"ChevronDown"}
            className={`w-2.5 h-2`}
            stroke={"#1B1C1E"}
            fill={"none"}
          />} styles={"py-2 px-4 whitespace-nowrap  text-F16 "} type="secondary_transparent"/>
            <Dropdown category={true} mainStyle={"!h-60 !w-48"}  handleSelect={handleSelectCondition} 
             isOpen={isDropdownOpen2} 
             options={category} 
             selectedValues={selectedValues}
             setIsDropdownOpen={setIsDropdownOpen2} />
            </div>
            </div>
          
            </div>
  </div>  
  <SeparatorComponent className={"!my-8"}/> 
  <div className={`flex flex-wrap gap-6 ${
              language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : "flex-row"
            } w-full`}>
         { loading ?
         <div className="flex h-[350px] w-full justify-center items-center">
         <Loader/>
         </div>
         : data?.length>0? 
          data.map((item,index)=>
(<DonationCard 
  onImageClick={()=>{ImageClick(item)}}
  onCartClick={()=>{handleCartDetails(item)}} 
  cardLoader={loadingStates[item.id]}  
  onClick={()=>{handleProjectClick(item)}} key={index}  data={item}/>))  
:
(!loading && <div className="flex w-full h-[350px] justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
              </div>)}
              
</div>

 
</div>

  </div>
 {isAddModal&& <DonationModal isAddModal={isAddModal} details={details} setIsAddModal={setIsAddModal}/>}
  </>)
};

export default DonationProjects;
