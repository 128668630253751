import { postRequest } from "./apis/methods";
import { apiRoutes } from "./apis/routes";
import "./App.css";
import { AppLayout } from "./views/appLayout";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { infoHandler } from "./store/slices/infoSlice";
const getDeviceFingerPrint = async () => {
  const fpPromise = await FingerprintJS.load();
  const result = await fpPromise.get();
  return result.visitorId;
};

const getDeviceInfo = async () => {
  const nAgt = navigator.userAgent;


  // Sample browser/device detection functions
const getBrowserName = () => {
  const edgeRegex = /Edg\/([\d\.]+)/; // Updated regex to detect Edge
  const browsers = {
    Edge: edgeRegex, // Edge detection first
    Firefox: /(?:Firefox|FxiOS)\/([\d\.]+)/,
    Safari: /Version\/([\d\.]+).*Safari/,
    Chrome: /(?:Chrome|CrMo|CriOS)\/([\d\.]+)/,
    IE: /MSIE ([\d\.]+).*Trident/,
    Opera: /(?:Opera|OPR)\/([\d\.]+)/,
  };

  for (const browserName in browsers) {
    const match = nAgt.match(browsers[browserName]);
    if (match) {
      return browserName;
    }
  }
  return 'Unknown';
};

const getDeviceType = () => {
  const isMobileUA = /Mobi|Android/i.test(nAgt);
  const isTabletUA = /Tablet|iPad/i.test(nAgt);
  const isDesktopUA = /Windows NT|Macintosh/i.test(nAgt);

  const screenWidth = window.screen.width;
  const isSmallScreen = screenWidth <= 768;

  if (isDesktopUA && !isSmallScreen) {
    return "Desktop";
  } else if (isTabletUA) {
    return "Tablet";
  } else if (isMobileUA || isSmallScreen) {
    return "Mobile";
  } else {
    return "Unknown";
  }
};

  const fingerPrintId = await getDeviceFingerPrint();

  return {
    user_agent: nAgt,
    device_width: window.screen.width,
    device_height: window.screen.height,
    browser_name: getBrowserName(),
    device_type: getDeviceType(),
    fingerprint_id: fingerPrintId,
  };
};

function App() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.openModel.value);
  // useEffect(() => {
  //   fetchDeviceInfo();
  // }, []);
  useEffect(()=>{
        getInit()
  },[isOpen?.type==="updateToast"||isOpen?.type==="updateToastError"||isOpen?.type==="clear"|| isOpen?.type==="initials"])
  // const fetchDeviceInfo = async () => {
  //   try {
  //     const info = await getDeviceInfo();
  //     localStorage.setItem("deviceId", info.fingerprint_id);
  //     console.log("INFOOO",info)
  //   } catch (error) {
  //     console.error("Error fetching device info:", error);
  //   }
  // };

const getInit=async()=>{
  try{
    const info = await getDeviceInfo();
const payload={
 
    device_id: info.fingerprint_id,
    app_version: "",
    device_os: "",
    device_os_version: "",
    device_type: info?.device_type,
    device_name: info?.browser_name,
    device_manufacturer: "Google",
    device_width: window.screen.width,
    device_height: window.screen.height,
    device_token: "1234hg23238bsihsd", //fcm token
    ip_address: "",
    last_activity_at: ""

}
const response = await postRequest(apiRoutes.get_init,payload); // Replace with your endpoint


if (response?.success) { 
  console.log("ResponseInfo", response);
  dispatch(infoHandler(response?.data))
}

  }
  catch (error) {
    console.error("Error fetching device info:", error);
  }
}

  return (
    <div className="w-full h-full">
      <AppLayout />
    </div>
  );
}

export default App;
