import React from "react";
import { GetAppText } from "../../../../utils";
import { useSelector } from "react-redux";

export const Achievement = () => {
  const language = useSelector((state) => state.language.value);
  const achievementData = [
    {
      value: "241",
      heading: GetAppText("donation_project"),
    },
    {
      value: "22.6 الف",
      heading: GetAppText("beneficiary"),
    },
    {
      value: "9,324",
      heading: GetAppText("donation_process"),
    },
  ];
  return (
    <div className="w-full h-full bg-custom-gradient-background relative sm:p-20 px-6 py-6  flex justify-center items-center">
      <div className="absolute top-0 right-0">
        <img className="object-cover" src={require("../../../../assets/images/Vector.png")} alt="logo" />
      </div>
      <div className=" flex items-center justify-center">
        <div className="grid justify-items-stretch space-y-16">
          <div className=" space-y-4 w-[85%] justify-self-center">
            <h1 className="text-white text-F32 font-bold text-center">
              {GetAppText("stride_to_achieve")}
            </h1>
            <h1 className="text-white sm:text-F28 text-F20 text-center">
              {GetAppText("achievement_desc")}
            </h1>
          </div>
          <div className="grid grid-cols-3 gap-24  md:w-[85%] w-[90%] justify-self-center">
            {(language === "_en"
              ? achievementData.slice().reverse()
              : achievementData
            ).map((item, index) => (
              <div
                className="flex flex-col  justify-center items-center"
                key={index}
              >
                <h1 className="text-secondary whitespace-nowrap md:text-F56 text-F33 font-bold">
                  {item.value}
                </h1>
                <h1 className="text-white whitespace-nowrap md:text-F24 text-F15">{item.heading}</h1>
                <div className="sm:h-2 h-1 w-full bg-white  md:mt-6 mt-4"  />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
