import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "_ar",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    switchLanguage: (state, action) => {
      console.log("Language", action.payload);
      state.value = action.payload;
    },
  },
});

export const { switchLanguage } = languageSlice.actions;

export default languageSlice.reducer;
