import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GetAppText } from "../../utils";
import { ReactComponent as SunniLogo } from "../../assets/svgs/sunniLogo.svg";
import { ReactComponent as ShoppingCart } from "../../assets/svgs/shoppingCart.svg";
import { ReactComponent as UserIcon } from "../../assets/svgs/userIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { switchLanguage } from "../../store/slices/languageSlice";
import { useLocation } from "react-router-dom";
import SvgComponent from "./SvgComponent";
import { ModalComponent } from "./Modal";
import { Login } from "../pages/Login";
import { Otp } from "../pages/Otp";
import Signup from "../pages/Signup";
import { modalHandler } from "../../store/slices/modalSlice";
import { ResetPassword } from "../pages/ResetPassword";
function Header() {
  const location = useLocation();
  const [langText, setLangText] = useState("EN");
  const [menuOpen, setMenuOpen] = useState(false);
  const [userToken, setUserToken] = useState("");

  const isOpen = useSelector((state) => state.openModel.value);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isloginModal, setIsloginModal] = useState(false);
  const [isSignupnModal, setIsSignupModal] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [isResetModal, setIsResetModal] = useState(false);
  const language = useSelector((state) => state.language.value);
  const data = useSelector((state) => state.userDetails.value);
  useEffect(() => {
    setLangText(language === "_en" ? "ع" : "EN");
  }, [language]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

// useEffect(()=>{

//   getLocalStorageData('token')
//   // localStorage.setItem("token")
// },[])

  // Function to handle changes to localStorage across different tabs/windows
  const handleStorageChange = (event) => {
    if (event.key === "token") { // Check for changes on the specific key
      console.log("Storage changed!", event.newValue);
      setUserToken(event.newValue); // Update state if localStorage value changes
    }
     // Handle the case where localStorage.clear() was called (event.key will be null)
     if (event.key === null) {
      console.log("Storage cleared!");
      setUserToken(""); // Clear the token state when localStorage is cleared
    }
  };
useEffect(() => {
  // Load data from localStorage when component mounts
  const storedData = getLocalStorageData("token");
  if (storedData) {

    setUserToken(storedData);
  }
  else{
    setUserToken("")
  }

  // Add event listener for `storage` event
  window.addEventListener("storage", handleStorageChange);

  // Clean up event listener on component unmount
  return () => {
    window.removeEventListener("storage", handleStorageChange);
  };
}, [localStorage.getItem("token")]);

const getLocalStorageData = (key) => {
  const storedValue = localStorage.getItem(key); // Retrieve from localStorage

  // console.log("Token",storedValue)
  return storedValue;
};
  useEffect(()=>{
    if(isOpen=="LoginModal")
  {  setIsloginModal(true)
    setIsSignupModal(false)
    setIsOtpModal(false)
  }
    else if(isOpen=="SignupModal"){
      setIsloginModal(false)
      setIsSignupModal(true)
      setIsOtpModal(false)
    }
    else if(isOpen=="OtpModal"){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(true)
    }
    else if(isOpen=="ResetModal"){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(false)
      setIsResetModal(true)
    }
    else if(isOpen==""){
      setIsloginModal(false)
      setIsSignupModal(false)
      setIsOtpModal(false)
      setIsResetModal(false)
    }
  
  },
  [isOpen])


  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // Scrolls to top whenever location (route) changes

  useEffect(() => {
    setLangText(language === "_en" ? "ع" : "EN");
  }, [language]);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const dispatch = useDispatch();
  const handleToggleLanguage = () => {
    dispatch(switchLanguage(language === "_en" ? "_ar" : "_en"));
  };
  return (
<nav className={`w-full sm:h-[135px]  h-[150px] pt-1 fixed ${location.pathname === "/" && !isScrolled ? "bg-[#FFFFFF40]" : "bg-white"} z-50`}>
  <div className={`w-full lg:px-14 px-7  flex  ${language === "_ar" ? "flex-row-reverse " : ""} items-center justify-between py-2.5`}>
    {/* Logo */}
    <NavLink to={"/"}>
    <div className="cursor-pointer ">
      <div className="justify-center flex items-center">
        <SunniLogo />
      </div>
      <h1 className={`lg:text-F11 xl:text-F12  text-F10 text-center max-w-full overflow-hidden ${location.pathname === "/"  && !isScrolled? "text-white" : "text-black"} font-semibold`}>
        {GetAppText("official_donation_portal")}
      </h1>
    </div>
</NavLink>
    {/* Mobile Menu Button */}
    

    {/* Menu */}
    {/* Menu */}
    <div className={` ${language === "_ar" ? "pr-7" : ""}  md:block hidden`}>
      <div className="w-full  md:w-auto" id="navbar-default">
        <ul
          className={`font-semibold flex flex-col xl:text-F18 lg:text-F16 md:text-F14 text-F13 p-4 md:p-0 mt-4 md:mt-0 ${
            language === "_ar" ? "md:flex-row-reverse md:space-x-reverse" : "md:flex-row"
          }`}
        >
          <li className={`${language === "_ar" ? "md:border-l-[2px] " : "md:border-r-[2px]"}  border-borderColor px-4`}>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" && !isScrolled? "text-secondary" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled? "text-white" : "text-black"}`
              }
            >
              {GetAppText("home")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? "md:border-l-[2px] " : "md:border-r-[2px]"} border-borderColor px-4`}>
            <NavLink
              to={"/donation-projects"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("donationProjects")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? "md:border-l-[2px] " : "md:border-r-[2px]"} border-borderColor px-4`}>
            <NavLink
              to={"/about-us"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" && !isScrolled? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" && !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("about_us")}
            </NavLink>
          </li>
   
      
          <li className="px-4">
            <NavLink
              to={"/technical-support"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/"&& !isScrolled ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("technical_support")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>

    {/* Options */}
    <div className="flex  items-center justify-center">
      <div className={`md:flex hidden ${language === "_ar" ? "flex-row-reverse md:space-x-reverse" : "flex-row"} md:space-x-4 md:space-y-0 space-y-3  w-full`}>
        <div className="lg:w-[280px] w-10/12">
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
            Search
          </label>
          <div className="relative">
            <div className={`absolute inset-y-0 flex items-center pointer-events-none ${language === "_ar" ? "end-0 pe-3" : "start-0 ps-3"}`}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                  stroke="#727272"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className={`block w-full p-2 py-2.5 placeholder:text-F12 text-sm placeholder:text-lightTextColor focus:outline-none focus:border-borderColor focus:ring-0 border-2 border-borderColor rounded-lg ${
                language === "_ar" ? "pe-5 text-right" : "ps-10"
              }`}
              placeholder={GetAppText("search_here")}
            />
          </div>
        </div>

        <div className={`  flex relative  ${language === "_ar" ? "md:flex-row-reverse md:space-x-reverse" : "flex-row"} items-center space-x-3`}>
          <div
              title={GetAppText("language")}
            className={`  items-center  w-10 h-10  justify-center flex ${location.pathname === "/" && !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} border-[2px] rounded-md cursor-pointer`}
            onClick={() => handleToggleLanguage()}
          >
            <span className={`${language === "_ar" ? "" : "-mt-1"} text-F15 font-semibold`}>{langText}</span>
          </div>
          <NavLink to={"/donars-bag"}>
            <div
                 title={GetAppText("donation_basket")}
            className={`w-10 h-10 items-center ${location.pathname === "/"&& !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} justify-center flex border-[2px] cursor-pointer rounded-md`}>
              <SvgComponent
                name={"Cart"}
                className={`w-6 h-5`}
                stroke={`${location.pathname === "/"&& !isScrolled ? "white " : "black"}`}
                fill={"none"}
              />
            </div>
          </NavLink>
         {userToken? 
         <NavLink to={"/profile"}>
            <div
             title={GetAppText("account_settings")}
            className="w-10 h-10 items-center justify-center cursor-pointer flex border-[2px] border-primary rounded-md bg-primary">
              <UserIcon />
            </div>
          </NavLink>
          :
        
            <div
            title={GetAppText("login")}
            onClick={()=>{dispatch(modalHandler("LoginModal"));}} className={`px-2 h-10 ${language === "_ar" ? " " : "flex-row-reverse space-x-reverse"} space-x-2 font-semibold items-center ${location.pathname === "/"&& !isScrolled ? "text-white border-white hover:bg-gray-300" : "text-black border-black hover:bg-gray-200"} justify-center 
            flex border-[2px] lg:text-F14 text-F12 cursor-pointer rounded-md`}>
                  <SvgComponent
                name={"Login"}
                className={"w-4 h-4"}
                stroke={`${location.pathname === "/"&& !isScrolled ?"white":"black"}`}
                fill={"none"}
              /> 
              <div>
              {GetAppText("login")}
              </div>
            </div>
       }

    <div className={`w-[160px] top-14 ${location.pathname === "/" ? "bg-[#FFFFFF40]" : "bg-white"}  px-3 ${language === "_ar" ? "" : ""} ${menuOpen ? "block absolute" : "hidden"} md:hidden`}>
      <div className="w-full md:block md:w-auto" id="navbar-default">
        <ul
          className={`font-semibold space-y-2 w-full flex flex-col py-3 md:p-0 mt-4 md:mt-0 ${
            language === "_ar" ? "flex-row-reverse md:space-x-reverse" : "flex-row"
          }`}
        >
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-secondary" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("home")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/about-us"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("about_us")}
            </NavLink>
          </li>
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/donation-projects"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("donationProjects")}
            </NavLink>
          </li>
     
          <li className={`${language === "_ar" ? " text-right" : ""} border-borderColor `}>
            <NavLink
              to={"/technical-support"}
              className={({ isActive }) =>
                isActive
                  ? `${location.pathname === "/" ? "text-white" : "text-secondary"}`
                  : `${location.pathname === "/" ? "text-white" : "text-black"}`
              }
            >
              {GetAppText("technical_support")}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
        
        </div>

      </div>
  {/* Mobile Screen */}
  <button
        data-collapse-toggle="navbar-default"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-700 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        aria-controls="navbar-default"
        aria-expanded="false"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <span className="sr-only">Open main menu</span>
        <svg
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 ${language === "_ar" ? "right-0" : "left-0"} w-72 h-full md:hidden bg-white shadow-lg z-50 transform transition-transform duration-300 ease-in-out ${
          sidebarOpen ? "translate-x-0" : language === "_ar" ? "translate-x-full" : "-translate-x-full"
        }`}
      >
        <div
          className={`p-4 ${language === "_ar" ? "flex-row-reverse" : ""} flex justify-between items-center border-b border-borderColor`}
        >
          <div className="flex items-center">
            <SunniLogo />
          </div>
          <button
            onClick={() => setSidebarOpen(false)}
            className="text-gray-600 hover:text-gray-800"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col justify-between space-y-10">
          <ul
            className={`flex flex-col font-semibold pt-6 text-F16 ${
              language === "_ar" ? "text-right" : "text-left"
            } space-y-5 p-4`}
          >
            {/* Menu Items */}
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "text-secondary" : "text-black"
                }
                onClick={() => setSidebarOpen(false)}
              >
                {GetAppText("home")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  isActive ? "text-secondary" : "text-black"
                }
                onClick={() => setSidebarOpen(false)}
              >
                {GetAppText("about_us")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/donation-projects"
                className={({ isActive }) =>
                  isActive ? "text-secondary" : "text-black"
                }
                onClick={() => setSidebarOpen(false)}
              >
                {GetAppText("donate")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/technical-support"
                className={({ isActive }) =>
                  isActive ? "text-secondary" : "text-black"
                }
                onClick={() => setSidebarOpen(false)}
              >
                {GetAppText("technical_support")}
              </NavLink>
            </li>
          </ul>

          <div
         
            className={`flex px-4 relative border-t pt-6 border-borderColor ${
              language === "_ar" ? "flex-row-reverse space-x-reverse" : "flex-row"
            } items-center space-x-3`}
          >
            <div
             title={GetAppText("language")}
              className={`items-center w-10 h-10 justify-center flex text-black border-black hover:bg-gray-200 border-[2px] rounded-md cursor-pointer`}
              onClick={() => handleToggleLanguage()}
            >
              <span className={`${language === "_ar" ? "" : "-mt-1"} text-F15 font-semibold`}>{langText}</span>
            </div>
            <NavLink to={"/donars-bag"}>
              <div
              title={GetAppText("donation_basket")}
                className={`w-10 h-10 items-center text-black border-black hover:bg-gray-200 justify-center flex border-[2px] cursor-pointer rounded-md`}
              >
                
                <SvgComponent
                  name={"Cart"}
                  className={`w-6 h-5`}
                  stroke={"black"}
                  fill={"none"}
                />
              </div>
            </NavLink>
            {userToken ? (
              <NavLink to={"/profile"}>
                <div className="w-10 h-10 items-center justify-center cursor-pointer flex border-[2px] border-primary rounded-md bg-primary">
                  <UserIcon />
                </div>
              </NavLink>
            ) : (
              <div
                onClick={() => {
                  dispatch(modalHandler("LoginModal"));
                }}
                className={`px-4 h-10 ${
                  language === "_ar" ? "" : "flex-row-reverse space-x-reverse"
                } space-x-2 font-semibold items-center text-black border-black hover:bg-gray-200 justify-center flex border-[2px] lg:text-F14 text-F12 cursor-pointer rounded-md`}
              >
                <SvgComponent
                  name={"Login"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
                <div>{GetAppText("login")}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Overlay to close sidebar */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setSidebarOpen(false)}
        ></div>
      )}
    </div>
  </div>
  <ModalComponent styles="!w-[460px]" isOpen={isloginModal} onClose={()=>{setIsloginModal(false);dispatch(modalHandler(""));}}>
    
    <Login className="" />
   
    </ModalComponent>

    <ModalComponent styles="!w-[460px]" isOpen={isOtpModal} onClose={()=>{setIsOtpModal(false);dispatch(modalHandler(""));}}>
  <Otp/>
  </ModalComponent>


    <ModalComponent styles="!w-[900px]" isOpen={isSignupnModal} onClose={()=>{setIsSignupModal(false);dispatch(modalHandler(""));}}>
  
  <Signup className="" />
 
  </ModalComponent>
  <ModalComponent styles="!w-[460px]" isOpen={isResetModal} onClose={()=>{setIsResetModal(false);dispatch(modalHandler(""));}}>
 <ResetPassword/>
    </ModalComponent>
</nav>

  );
}

export default Header;
