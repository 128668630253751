import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../../utils";
import { useSelector } from "react-redux";
import { apiRoutes } from "../../../../../apis/routes";
import { getRequest } from "../../../../../apis/methods";
import { Loader } from "../../../../components/Loader";

export const TermsOfUse = () => {
  const language = useSelector((state) => state.language.value);
  const[loading,setLoading]=useState(false)
const[data,setData]=useState("")
useEffect(()=>{
  fetchData()
},[])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_terms}`
       
        ); // Replace with your endpoint
if(response.success)
   { 
    setLoading(false)
    setData(response?.data?.terms)
   
    console.log("Results",response)
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };
  // const data = [
  //   {
  //     id: 1,
  //     heading: "",
  //     para: "مرحبًا بك في صفحة شروط الاستخدام والأحكام لتطبيق افعلوا الخير. يرجى قراءة هذه الشروط بعناية قبل استخدام التطبيق. باستخدام تطبيق افعلوا الخير، فإنك توافق على الامتثال لشروط وأحكام هذا الاتفاق، وتعتبر هذه الموافقة ملزمة قانونًا.",
  //   },
  //   {
  //     id: 2,
  //     heading: "رؤية ورسالة الخير",
  //     para: "باستخدامك لتطبيق افعلوا الخير، فإنك تؤكد موافقتك وقبولك لجميع الشروط والأحكام المنصوص عليها هنا. تلتزم بالامتثال لهذه الشروط والأحكام أثناء استخدام التطبيق، وتعتبر هذه الموافقة ملزمة قانونًا. يجب عليك أيضًا الموافقة على تحديثات الشروط والأحكام التي قد تطرأ عليها في المستقبل.",
  //   },
  //   {
  //     id: 3,
  //     heading: "دورنا في تعزيز التغيير",
  //     para: "يجب عليك استخدام تطبيق افعلوا الخير فقط للأغراض المشروعة التي تشمل دعم الأعمال الخيرية والمشاركة في الأنشطة التطوعية. يحظر استخدام التطبيق لأي أغراض غير قانونية أو غير أخلاقية. يجب عليك أن تكون مسؤولاً عن استخدامك الشخصي للتطبيق والتحقق من مطابقته للقوانين والأنظمة المحلية.",
  //   },
  //   {
  //     id: 4,
  //     heading: "شراكاتنا ومساهماتكم",
  //     para: "سنقوم بجمع ومعالجة المعلومات الشخصية التي تقدمها بموافقتك الصريحة وفقًا لسياسة الخصوصية لتطبيق افعلوا الخير. يتم استخدام هذه المعلومات فقط لأغراض تشغيلية وتحسين تجربتك كمستخدم، ولن نقوم ببيعها أو مشاركتها مع أطراف ثالثة دون موافقتك الصريحة. تحتفظ بحقك في مراجعة وتحديث المعلومات الشخصية الخاصة بك وفقًا للسياسة المحددة في تطبيق افعلوا الخير.",
  //   },
  //   {
  //     id: 5,
  //     heading: "لتزامنا بالجودة والشفافية",
  //     para: "لا يتحمل تطبيق افعلوا الخير أي مسؤولية عن أي أضرار أو خسائر ناتجة عن استخدام أو عدم القدرة على استخدام التطبيق. تتحمل كامل المسؤولية عن استخدامك للتطبيق وتفاعلك مع المحتوى والخدمات المقدمة من خلاله.",
  //   },
  // ];
  return (
    <div>
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("terms_use_conditions")}
      </h1>
      <div className="pt-8">
      {loading?  <div className="flex h-[550px] w-full justify-center items-center">
 <Loader/>
 </div>
 :data.length>0?
 data?.map((items,index)=>(
  <div key={index} className={`${language === "_ar" ?"text-right":"text-left"}`} dangerouslySetInnerHTML={{ __html: language === "_ar" ? items?.value_ar:items?.value }}>
  </div>
   ))
        :
        (!loading && <div className="flex w-full bg-white rounded-xl h-[450px] justify-center text-F20 font-semibold items-center">
                      {GetAppText("no_data")}
                      </div>)
        
        }
   
      </div>
    
    </div>
  );
};
