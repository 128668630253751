import React, { useEffect, useState } from "react";
import { ButtonComponent } from "../../components/ButtonComponent";
import { GetAppText } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { ReactComponent as Bpay } from "../../../assets/svgs/Bpay.svg";
import { ReactComponent as Applepay } from "../../../assets/svgs/applePay.svg";
import { ReactComponent as Visa } from "../../../assets/svgs/visa.svg";
import Signup from "../Signup";
import { ReactComponent as UnfillCheckbox } from "../../../assets/svgs/checkboxUnfill.svg";
import { ReactComponent as FillCheckbox } from "../../../assets/svgs/checkboxFill.svg";
import { ReactComponent as PayCheck } from "../../../assets/svgs/paymentCheck.svg";
import { ReactComponent as PayCross } from "../../../assets/svgs/paymentCross.svg";
import { InputComponent } from "../../components/InputComponent";
import { ModalComponent } from "../../components/Modal";
import { useNavigate } from 'react-router-dom';
import { Login } from "../Login";
import { Otp } from "../Otp";
import { useParams } from 'react-router-dom';
import { modalHandler } from "../../../store/slices/modalSlice";
import { ResetPassword } from "../ResetPassword";
import { getRequest } from "../../../apis/methods";
import { apiRoutes } from "../../../apis/routes";
const Checkout = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
    const language = useSelector((state) => state.language.value);
    const isOpen = useSelector((state) => state.openModel.value);
    const isDonate = useSelector((state) => state.donation.value);
    const invoiceData = useSelector((state) => state.data.value);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isloginModal, setIsloginModal] = useState(false);
    const [isSignupnModal, setIsSignupModal] = useState(false);
    const [isOtpModal, setIsOtpModal] = useState(false);
    const [isResetModal, setIsResetModal] = useState(false);
    const[token,setToken]=useState("")
    const[count,setCount]=useState("")
useEffect(()=>{
  if(isOpen=="LoginModal")
{  setIsloginModal(false)
  setIsSignupModal(false)
  setIsOtpModal(false)
}
  else if(isOpen=="SignupModal"){
    setIsloginModal(false)
    setIsSignupModal(true)
    setIsOtpModal(false)
  }
  else if(isOpen=="OtpModal"){
    setIsloginModal(false)
    setIsSignupModal(false)
    setIsOtpModal(true)
  }
  else if(isOpen=="ResetModal"){
    setIsloginModal(false)
    setIsSignupModal(false)
    setIsOtpModal(false)
    setIsResetModal(true)
  }

},
[isOpen])
useEffect(()=>{
  console.log("CheckOut",invoiceData)
},[])
useEffect(()=>{

  const storedValue = localStorage.getItem("token");
  setToken(storedValue)
},[])

const[selected,setSelected]=useState('')

const payment=[
    {id:1,name:"Apple Pay",svg:<Applepay/>,},
    {id:2,name:"طاقة ائتمان",svg:<Visa/>},
    {id:3,name:"بطاقة خصم",svg:<Bpay/>},
]
const hadith="مَنْ دَلَّ عَلَى خَيْرٍ فَلَهُ مِثْلُ أَجْرِ فَاعِلِهِ (حديث نبوي)"

const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cvv, setCVV] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const[data,setData]=useState('')
  const[totalprice,setTotalPrice]=useState('')
  const [loading,setLoading]=useState(false)
  const { id } = useParams();
  const handlePayClick = () => {
    const payload = {
      paymentId:selected,
      cardName: cardName,
      cardNumber:cardNumber,
      CVV: cvv,
      expiryDate: `${expiryMonth}/${expiryYear}`,
    };
    openModal()
    console.log(payload);
    // Handle the payload, e.g., send to an API
  };


  const handleLogin=()=>{
      // navigate(`/login`);
      setIsloginModal(true)
  }
  const handleSignup=()=>{
    // navigate(`/signup`);
    setIsSignupModal(true)
}
useEffect(()=>{
  const isDonate = localStorage.getItem('Donate');
  if(id==="donation")
 {
  fetchDonate()
  }
  else{
    fetchData()
  }
},[]) 

const fetchData = async () => {
  console.log("Dataa")
  setLoading(true)
  try {
    const response = await getRequest(`${apiRoutes.get_cart}`
    
      ); // Replace with your endpoint
if(response.success)
 { 
  setLoading(false)
  setData(response?.data?.cart)
  setCount(response?.data?.count)
  setTotalPrice(response?.data?.total_price)
  console.log("Results",response)
}
else{
  setLoading(false)
}
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
};
const fetchDonate = async () => {

  setLoading(true)
  try {
    const response = await getRequest(`${apiRoutes.get_donate}`
    
      ); // Replace with your endpoint
if(response.success)
 { 
  setLoading(false)
  setData(response?.data?.donation_cart)
  setCount(response?.data?.count)
  setTotalPrice(response?.data?.total_price)
  console.log("Results",response)
}
else{
  setLoading(false)
}
  } catch (error) {
    setLoading(false)
    console.error('Error fetching data:', error);
  }
};
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return (
    <>
    <div className="px-20 py-16 w-full space-y-6">
<div className={`w-full flex items-center ${language === "_ar"
          ? "flex-row-reverse"
          : ""
        }  justify-between `}>
        <div className={`flex ${language === "_ar"
            ? ""
            : "flex-row-reverse space-x-reverse"
          }     items-center space-x-2`}>
   
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-primary text-F24 font-bold `}
          >
            {GetAppText("payment_page")}
          </h2>
        </div>
  {!token &&     <div className={`flex items-center space-x-3 ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : "flex-row"
          }    `}>

          <ButtonComponent title={GetAppText("create_account")} onClick={handleSignup} styles={" px-5 whitespace-nowrap !py-2.5  text-F16"} type="primary" />
          <ButtonComponent title={GetAppText("login")}  onClick={handleLogin} styles={" px-5 whitespace-nowrap  text-F16 !py-2.5"} type="secondary_transparent" />
        </div>}

      </div>
      <div className={`w-full space-x-11 flex items-start ${language === "_ar"
          ? "flex-row-reverse space-x-reverse"
          : ""
        }  justify-between `}>
          {/* Payment Summary */}
        <div className={`w-full bg-white rounded-xl p-6 flex-col items-center `}>
            <div className={`w-full flex items-center   ${language === "_ar"
            ? "flex-row-reverse"
            : ""
          }  justify-between `}>
          <div className={`flex ${language === "_ar"
              ? ""
              : "flex-row-reverse space-x-reverse"
            }     items-center space-x-2`}>
          {count>0&&  <ButtonComponent title={count} styles={"!py-0.5 !px-2 whitespace-nowrap   text-F14"} type="primary" />}
            <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("donation_summary")}
            </h2>
          </div>
      {id!=="donation"&& <div onClick={()=>{navigate(`/donars-bag`);}} className={`flex items-center cursor-pointer space-x-1 ${language === "_ar"
              ? ""
              : "flex-row-reverse space-x-reverse space-x-2"
            }    `}>
  
  <SvgComponent
                        name={"Edit"}
                        className={"w-4 h-4"}
                        fill="none"
                        stroke={"#E49E4E"}
                      />
          <h2
              className={`flex items-center hover:underline ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-secondary text-F14 font-bold `}
            >
              {GetAppText("edit")}
            </h2>
          </div>}
  
        </div>
        <div className="pt-5 pb-2 space-y-3.5">
      {data?.items&& data?.items?.map((item)=>(
        <div key={item?.id} className={`flex ${language === "_ar"
            ? ""
            : "flex-row-reverse space-x-reverse"
          }  justify-between   items-center space-x-2`}>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-secondaryTextColor text-F13 font-semibold  space-x-1`}
          >
            <div>
            {item?.price}</div> <div>{GetAppText("bhd")}</div>
          </h2>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  `}
          >
            {/* {item?.itemable?.} */}
            { language === "_ar" ?item?.itemable?.title_ar:item?.itemable?.title}
          </h2>

        </div>
      )) }
      </div>
        <SeparatorComponent className="!my-2.5"/>
        <div className={`flex ${language === "_ar"
                ? ""
                : "flex-row-reverse space-x-reverse"
              }  justify-between   items-center space-x-2`}>
              <h2
                className={`flex items-center ${language === "_ar"
                    ? "flex-row-reverse space-x-reverse"
                    : ""
                  } text-black text-F14 font-bold  space-x-1`}
              >
                <div>
                  {totalprice?.toLocaleString()}</div> <div className="text-F14">{GetAppText("bhd")}</div>
              </h2>
              <h2
                className={`flex items-center ${language === "_ar"
                    ? "flex-row-reverse  space-x-reverse"
                    : ""
                  } text-black text-F14 font-semibold  `}
              >
                {GetAppText("total")}
              </h2>

            </div>
        
        </div>

        {/* Payment Method */}
        <div className={`w-7/12 bg-white rounded-xl p-6 space-y-8  items-center ${language === "_ar"
          ? "flex-row-reverse"
          : ""
        }  `}>
            <div>
 <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("payment_method")}
            </h2>
            <div className={`pt-6  pb-2 space-y-3`}>
      { payment?.map((item)=>(
        <div key={item?.id} className={`flex ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : ""
          }     items-center space-x-3.5`}>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-secondaryTextColor text-F13 font-semibold  space-x-1`}
          >
            <div className="cursor-pointer" onClick={()=>{setSelected(item?.id)}}>{item?.id==selected?<FillCheckbox/>:<UnfillCheckbox/>}</div>
          </h2>
          <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse  space-x-reverse"
                : ""
              } text-black text-F14 font-semibold  `}
          >
            {item?.name}
          </h2>
<div>
    {item.svg}
</div>
        </div>
      )) }
      </div>
      </div>
      <div className="space-y-5">
      <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-primary text-F16 font-bold `}
            >
              {GetAppText("card_info")}
            </h2>
            <div className="space-y-5">
            <div className="space-y-2">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("card_name")}
          </h2>
<InputComponent input_style={'!py-3'} value={cardName} onChange={(e) => setCardName(e.target.value)}/>
</div>
<div className="space-y-2">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("card_number")}
          </h2>
<InputComponent input_style={'!py-3'}  value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} />
</div>
<div className="flex items-center space-x-4 justify-between">
<div className="space-y-2 w-full">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
       (CVV)  {GetAppText("security_code")} 
          </h2>
<InputComponent input_style={'!py-3'} value={cvv} onChange={(e) => setCVV(e.target.value)} />
</div>
<div className="space-y-2 w-full">
            <h2
            className={`flex items-center ${language === "_ar"
                ? "flex-row-reverse space-x-reverse"
                : ""
              } text-black text-F12 font-semibold  space-x-1`}
          >
           {GetAppText("expiry_date")}
          </h2>
          <div className="flex items-center">
<InputComponent isTranslate={false} title="mm"   value={expiryMonth}
                onChange={(e) => setExpiryMonth(e.target.value)} input_style={'!py-3 !text-center !rounded-s-lg !border-r-0 !rounded-none'}/>
<InputComponent  isTranslate={false} title="yy"   value={expiryYear}
                onChange={(e) => setExpiryYear(e.target.value)} input_style={'!py-3 !text-center !rounded-e-lg !rounded-none'}/>
</div>
</div>
</div>
</div> 

</div>
 
<div className="space-y-2">
    <div className="flex flex-row-reverse items-center justify-center space-x-1 space-x-reverse">
    <h2
              className={`flex items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-secondaryTextColor text-F12 font-semibold `}
            >
              {GetAppText("continue")}
            </h2>
            <h2
              className={`flex underline cursor-pointer  items-center ${language === "_ar"
                  ? "flex-row-reverse  space-x-reverse"
                  : ""
                } text-black text-F12 font-extrabold `}
            >
              {GetAppText("terms_use")}
            </h2>
    </div>
<ButtonComponent  onClick={handlePayClick} title={GetAppText("move_pay")} styles={"!py-2.5 w-full px-4 whitespace-nowrap   text-F16"} type="secondary" />
</div>
        </div>
      </div>
    
    </div>
    <ModalComponent styles="!w-[460px]" isOpen={isloginModal} onClose={()=>{setIsloginModal(false);dispatch(modalHandler(""));}}>
    
      <Login className="" />
     
      </ModalComponent>

      {/* <ModalComponent styles="!w-[460px]" isOpen={isOtpModal} onClose={()=>{setIsOtpModal(false);dispatch(modalHandler(""));}}>
    <Otp/>
    </ModalComponent> */}


      <ModalComponent styles="!w-[900px]" isOpen={isSignupnModal} onClose={()=>{setIsSignupModal(false);dispatch(modalHandler(""));}}>
    
    <Signup className="" />
   
    </ModalComponent>
    
    <ModalComponent styles="!w-[460px]" isOpen={isResetModal} onClose={()=>{setIsResetModal(false);dispatch(modalHandler(""));}}>
 <ResetPassword/>
    </ModalComponent>

    {/* Modal */}
    <ModalComponent isOpen={isModalOpen} onClose={closeModal}>
      <div className="relative flex justify-center w-full ">
      <div className="absolute top-0 right-0">
        <img src={require("../../../assets/images/Vector.png")}className="h-44 w-44" alt="logo" />
      </div>
      
        <div className=" flex flex-col items-center pt-12 pb-7 px-7 space-y-4">
         {paymentSuccess? <PayCheck/> :<PayCross/>}
          <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse"
                  : ""
                } text-white text-F20 font-bold `}
            >
              {paymentSuccess? GetAppText("donation_successful"):GetAppText("payment_failed")}
            </h2>
            <h2
              className={`flex items-center text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-modelTextColor text-F17 font-light `}
            >
              {paymentSuccess? GetAppText("donation_success_msg"):GetAppText("donation_failed_msg")}
            </h2>
           {paymentSuccess && <h2
              className={`flex items-center py-4 text-center ${language === "_ar"
                  ? "flex-row-reverse  "
                  : ""
                } text-secondary text-F19 font-normal `}
            >
              {hadith}
            </h2>}
            <div className={`flex items-center w-full space-x-3 ${language === "_ar"
            ? "flex-row-reverse space-x-reverse"
            : "flex-row"
          }    `}>

          <ButtonComponent title={paymentSuccess? GetAppText("share_project"):GetAppText("retry")} styles={"py-3 px-4 whitespace-nowrap w-full  text-F16"} type="secondary" />
          <ButtonComponent title={GetAppText("back_to_main")} styles={"py-2.5 px-4 whitespace-nowrap w-full text-F16 "} onClick={closeModal} type="transparent" />
        </div>

        </div>
        </div>
     
      </ModalComponent>
    </>
  );
};

export default Checkout;
