import { useState, useRef, useEffect } from 'react';
import { GetAppText } from "../../../utils";
import { useSelector } from "react-redux";
import SvgComponent from '../SvgComponent';
import { ReactComponent as SquareCheckboxFill } from "../../../assets/svgs/squareCheckBox.svg";
const Dropdown = ({ isOpen, options, setIsDropdownOpen,handleSelect,mainStyle ,phone,category,sort,selectedValues }) => {

  const dropdownRef = useRef(null);
  const language = useSelector((state) => state.language.value);


  // Close the dropdown if the user clicks outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      {isOpen && (
        <div className={`absolute ${mainStyle} ${language === "_ar" ? "right-0 " : "left-0"} z-40  mt-0.5 w-36  overflow-y-auto  bg-white border border-gray-200 rounded-lg shadow-lg`}>
          {options&&options?.map((option, index) => (
            <div className='flex items-center'>
        

            <button
              key={index}
              onClick={() => {handleSelect(option)}}
              href={option.link || "#"}
              className={`${language === "_ar" ? "text-right flex-row-reverse " : "text-left"} flex  items-center  px-4 gap-2 w-full py-3 text-gray-700 -rounded-lg hover:bg-gray-100`}
            >
                {category && (
                  <div>
                    {selectedValues.some((val) => val.id === option.id) ? (
                      <SquareCheckboxFill  />
                    ) : (
                      <div className="w-5 h-5 border border-gray-300 rounded-md"></div>
                    )}
                  </div>
                )}
                 <div className={` flex ${language === "_ar" ? "flex-row-reverse " : "text-left"}`}>
              { phone ? option.phone_code :category? `${language === "_ar" ?option.title_ar:option.title}`:`${language === "_ar" ?option.key_ar:option.key}`}
              </div>
            </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
