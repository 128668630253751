import { useSelector } from "react-redux";
import SvgComponent from "../SvgComponent";

export const AccordionItem = ({ title, content, index, isOpen, onToggle }) => {

    const language = useSelector((state) => state.language.value);

    return (
      <div className="w-full">
    
          <button
            type="button"
            className={`flex items-center ${language === "_ar" ? "flex-row-reverse " : ""} justify-between ${
                isOpen ? 'text-black font-bold' : 'text-secondaryTextColor font-medium'
              }  w-full  rtl:text-right text-F16  focus:ring-0  `}
            aria-expanded={isOpen}
            onClick={() => onToggle(index)}
          >
            <span>{title}</span>
        <SvgComponent/>
        <SvgComponent
        name={"ChevronDown"}
        className={`w-3 h-3 transition-transform text-secondaryTextColor transform ${isOpen ? 'rotate-180' : ''} shrink-0`}
        stroke={"#727272"}
        fill={"none"}
      />


          </button>
       
        <div
          className={ `  ${isOpen ? 'border-b border-b-1 border-b-borderColor' : 'hidden'} w-full `}
          aria-labelledby={`accordion-collapse-heading-${index + 1}`}
        >
          <div className={` pb-6 pt-5 text-F16 font-normal flex ${language === "_ar" ? "flex-row-reverse " : ""} ${isOpen ? 'text-black' : 'text-secondaryTextColor '}  dark:border-gray-700 dark:bg-gray-900`}>
            {content}
          </div>
        </div>
      </div>
    );
  };
  