import React from "react";
import { GetAppText } from "../../../../../utils";
import { useSelector } from "react-redux";

export const DonationRecord = () => {
  const language = useSelector((state) => state.language.value);
  const textAlignStyle = language === "_ar" ? "right" : "left";

  const table_data = [
    {
      heading: GetAppText("donation_date"),
      values: ["2024-07-01", "2024-07-01", "2024-07-01", "2024-07-01"],
    },
    {
      heading: GetAppText("project_name"),
      values: [
        "المساعدة الطبية للأطفال",
        "التعليم للجميع",
        "التعليم للجميع	",
        "التعليم للجميع	",
      ],
    },
    {
      heading: GetAppText("donation_amount"),
      values: ["20.0 د.ب", "20.0 د.ب", "20.0 د.ب", "20.0 د.ب"],
    },
    {
      heading: GetAppText("project_status"),
      values: [
        GetAppText("complete"),
        GetAppText("pending"),
        GetAppText("complete"),
        GetAppText("pending"),
      ],
    },
    {
      heading: GetAppText("transaction_number"),
      values: ["TRX123456789", "TRX123456789", "TRX123456789", "TRX123456789"],
    },
  ];
  const renderValue = (value) => {
    if (value === "مكتمل" || value === "Complete") {
      return (
        <div className="border w-fit border-successColor text-center px-2 py-1 rounded-xl bg-statusGreenBorder">
          <span className="text-successColor">{value}</span>
        </div>
      );
    } else if (value === "قيد التنفيذ" || value === "Pending") {
      return (
        <div className="border w-fit border-secondary text-center px-2 py-1 rounded-xl bg-statusOrangeBorder">
          <span className="text-secondary">{value}</span>
        </div>
      );
    } else {
      return value;
    }
  };
  return (
    <div className="w-full h-full space-y-6">
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("donation_record")}
      </h1>
      <div >
        <table
          dir={language === "_ar" ? "rtl" : "ltr"}
          className="w-full bg-white rounded-xl"
        >
          <thead>
            <tr>
              {table_data.map((data, index) => (
                <th
                  key={index}
                  className="text-left text-F14 border-b border-borderSecondaryColor text-secondaryTextColor font-normal px-6 py-5"
                  style={{ textAlign: textAlignStyle }}
                >
                  {data.heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {table_data[0]?.values.map((_, rowIndex) => (
              <tr key={rowIndex}>
                {table_data.map((data, colIndex) => (
                  <td
                    key={colIndex}
                    className="text-F12 p-6 py-5"
                    style={{ textAlign: textAlignStyle }}
                  >
                    {renderValue(data.values[rowIndex])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
