import { useState,useEffect } from 'react';
import { GetAppText } from "../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { ModalComponent } from '../Modal';
import { ButtonComponent } from '../ButtonComponent';
import { SeparatorComponent } from '../Separator';
import { InputComponent } from '../InputComponent';
import { apiRoutes } from '../../../apis/routes';
import { getRequest, postRequest } from '../../../apis/methods';
import { modalHandler } from "../../../store/slices/modalSlice";
import { donationHandler } from "../../../store/slices/donationSlice";
import { useNavigate } from 'react-router-dom';
const DonationModal = ({ isAddModal,details,setIsAddModal}) => {
    const dispatch = useDispatch();
  const language = useSelector((state) => state.language.value);
  const isOpen = useSelector((state) => state.openModel.value);

  const [addloading,setAddLoading]=useState(false)
  const navigate = useNavigate();

const [price,setPrice]=useState(0)
useEffect(()=>{
  console.log("FetchDataa")
  fetchData()
},[])

const handleSubmit = async (item) => {
    // Prevent multiple submissions
  if (addloading) return; 
  // Prevent multiple submissions
  
    const data = {
      itemable_type: "project",
      itemable_id:details?.id,
  
      price: price  
    };
   
  if(price){
    setAddLoading(true)
if(isOpen==="isDonate"){
  try {
    const response = await postRequest(apiRoutes.add_donate, data); // Replace with your endpoint
    console.log("Response", response);
 
    if (response?.success) { 
      setAddLoading(false)
      setIsAddModal(false)
      dispatch(modalHandler(""))
      // dispatch(donationHandler("Donate"))
      // localStorage.setItem('Donate',true)
      navigate(`/checkout/donation`);
      setPrice("")
 
    } else {
      setAddLoading(false)
      setIsAddModal(false)
      dispatch(modalHandler({ type: "toastError", msg: response?.data?.message}));
      setTimeout(()=>{
          dispatch(modalHandler(""));
        },[500])
      setPrice("")
     
    }
  } 
  catch (error) {
    console.error('Error fetching data:', error);
  }

}
else
  {  
  
    try {
      const response = await postRequest(apiRoutes.add_cart, data); // Replace with your endpoint
      console.log("Response", response);
   
      if (response?.success) { 
        setAddLoading(false)
        setIsAddModal(false)
        dispatch(modalHandler({ type: "toast", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
  
        setPrice("")
   
      } else {
        setAddLoading(false)
        setIsAddModal(false)
        dispatch(modalHandler({ type: "toastError", msg: response?.message}));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])
        setPrice("")
       
      }
    } 
    catch (error) {
      console.error('Error fetching data:', error);
    }
  
  }
  
  }
    else{
        setAddLoading(false)
     
        dispatch(modalHandler("priceError"));
        setTimeout(()=>{
            dispatch(modalHandler(""));
          },[500])

    }
  
  };

  const fetchData = async () => {
 
    try {
      const response = await getRequest(`${apiRoutes.get_cart_price}?itemable_id=${details?.id}&itemable_type=${'project'}`); // Replace with your endpoint
if(response.success)
   { 
   
    setPrice(response?.data?.price)
    console.log("Results",response)
  }
  else{
    
  }
    } catch (error) {
    
      console.error('Error fetching data:', error);
    }
  };


//   const inputRef = useRef("");

// useEffect(() => {
//   inputRef.current.focus();
// }, []);

  return (
<ModalComponent styles="!w-[900px]" isOpen={isAddModal} onClose={()=>{setIsAddModal(false);setPrice("")}}>
    
    {/* <Login className="" /> */}
    <div
        className={`bg-primary relative w-full rounded-lg p-7 ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
   
      <div className="flex flex-col items-center justify-center space-y-9">
      <div
        className={`flex ${
          language === "_ar" ? " " : "flex-row-reverse space-x-reverse "
        } space-x-10  w-full justify-between`}
      >
<div className="w-[65%] h-full">
        {/* {
          details?.media?.map((item,index)=>(
            index === 0 && <img src={item?.thumbnail} className={`object-cover  h-[300px] rounded-lg`} alt="logo" />
          ))
        } */}
        <img src={details?.image} className={`object-cover  h-[300px] rounded-lg`} alt="logo" />
</div>

      <div className="w-full text-white space-y-3">
          <div
            className={`flex ${
              language === "_ar" ? "flex-row-reverse " : ""
            } items-center justify-between`}
          >
            <h1 className="font-extrabold text-F24 ">{ language === "_ar" ?details?.title_ar :details?.title}</h1>
       
          </div>
          <div className={`relative font-normal ${language === "_ar" ?"text-right ":"text-left"}  text-F15`}>{language === "_ar" ?details?.description_ar :details?.description}</div>
     
          <SeparatorComponent className={"!border-borderSecondaryColor  !my-6"} />
         
          <div className=" w-full">
            <InputComponent   
            // ref={(el) => (inputRef.current = el)} 
            type="number" title="donation_amount" input_style={'!py-3 !text-black'} value={price}  onChange={(e) => setPrice(e.target.value)}/>
            </div>
   
        </div>
        </div>
        <div className="w-full"> 
            <div className="space-x-4 w-full flex items-center">
            <ButtonComponent disabled={addloading}  loading={addloading} styles={"!w-full !py-2.5"} onClick={handleSubmit} title={isOpen==="isDonate"?GetAppText("donate_now"):GetAppText("add_cart")} type="secondary" />
              <ButtonComponent styles={"!w-full !py-2.5"} onClick={()=>{setIsAddModal(false);setPrice("")}} title={GetAppText("cancel")} type="transparent"/>
          
            </div>
          </div>
      </div>

    </div>
   
    </ModalComponent>
  );
};

export default DonationModal;
