import React, { useEffect, useState } from "react";
import { GetAppText } from "../../../../../utils";
import { useSelector } from "react-redux";
import { getRequest } from "../../../../../apis/methods";
import { apiRoutes } from "../../../../../apis/routes";
import { Loader } from "../../../../components/Loader";

export const PrivacyPolicy = () => {
  const language = useSelector((state) => state.language.value);
  const[loading,setLoading]=useState(false)
const[data,setData]=useState("")
useEffect(()=>{
  fetchData()
},[])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getRequest(`${apiRoutes.get_policy}`
       
        ); // Replace with your endpoint
if(response.success)
   { 
    setLoading(false)
    setData(response?.data?.privacy)
   
    console.log("ResultsPrivacy",response?.data?.privacy)
  }
  else{
    setLoading(false)
  }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div className="w-full h-full">
      <h1
        className={`text-primary text-F24 font-bold ${
          language === "_ar" ? "text-right" : "text-left"
        }`}
      >
        {GetAppText("privacy_policy")}
      </h1>
      <div className="pt-8">
      {loading?  <div className="flex h-[550px] w-full justify-center items-center">
 <Loader/>
 </div>
 :data.length>0?
 data?.map((items,index)=>(
  <div key={index} className={`${language === "_ar" ?"text-right":"text-left"}`} dangerouslySetInnerHTML={{ __html: language === "_ar" ? items?.value_ar:items?.value }}>
  </div>
   ))
        :
        (!loading && <div className="flex w-full bg-white rounded-xl h-[450px] justify-center text-F20 font-semibold items-center">
                      {GetAppText("no_data")}
                      </div>)
        
        }
   
      </div>
    
    </div>
  );
};
