import React, { useEffect, useState } from "react";

import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { GetAppText } from "../../../utils";

import { ButtonComponent } from "../../components/ButtonComponent";
import { useNavigate } from 'react-router-dom';
import SvgComponent from "../../components/SvgComponent";
import { SeparatorComponent } from "../../components/Separator";
import { apiRoutes } from "../../../apis/routes";
import { getRequest } from "../../../apis/methods";
import { Loader } from "../../components/Loader";
import { NewsCardComponent } from "../../components/NewsCardComponent";

const News = () => {
 
const { id } = useParams();
const navigate = useNavigate();
const [loading,setLoading]=useState(false)
const[data,setData]=useState('')
const [storyloading,setStoryLoading]=useState(false)
const[storyData,setstoryData]=useState('')
const[otherData,setOtherData]=useState('')

        useEffect(()=>{
          fetchData()
          
        },[id])

        // useEffect(()=>{
        //   otherStoriesData()
        // },[])
        const fetchData = async () => {
          setLoading(true)
          try {
            const response = await getRequest(`${apiRoutes.get_newsDetails}/${id}`); // Replace with your endpoint
      if(response.success)
         { 
          setLoading(false)
          setData(response?.data?.news)
          setOtherData(response?.data?.other_news)
          }
        else{
          setLoading(false)
        }
          } catch (error) {
            setLoading(false)
            console.error('Error fetching data:', error);
          }
        };



        const language = useSelector((state) => state.language.value);


          const handleProjectClick = (id) => {
            console.log("ID",id)
            navigate(`/news/${id}`);
          };
          

  return (
    <div className="md:px-20 px-12 py-12 w-full space-y-10">
{      loading?    <div className="flex justify-center items-center h-[540px]">
      <Loader/>
      </div>:
      <div
        className={` ${
          language === "_ar" ? " " : " space-x-reverse "
        }  w-full justify-between space-y-6`}
      >
         <div
            className={`flex ${
              language === "_ar" ? "flex-row-reverse " : ""
            } items-center justify-between`}
          >
            <h1 className="font-bold text-F24 ">{language === "_ar" ?data?.title_ar:data?.title}</h1>
            <ButtonComponent
              title={GetAppText("sharing")}
              svg={
                <SvgComponent
                  name={"ShareSvg"}
                  className={"w-4 h-4"}
                  stroke={"black"}
                  fill={"none"}
                />
              }
              styles={"!py-2 !px-3 !text-black !text-F16"}
              type="secondary_transparent"
            />
          </div>
<div className="w-full ">
        <img src={data?.image} className="h-[550px] object-cover w-full rounded-lg" alt="logo" />
        </div>
        <div className="w-full text-primary  pt-8 pb-4">
         
          <div className={`font-normal ${
          language === "_ar" ? "text-right" : "text-left "} text-F16  `}>{language === "_ar" ?data?.description_ar:data?.description}</div>

        </div>
      </div>}
<SeparatorComponent  />
<div  className="space-y-8" >

<h1 className={` flex ${
          language === "_ar" ? "flex-row-reverse " : " "}
        font-semibold text-primary text-F30 `}>{GetAppText("Other_news")}</h1>

    <div className={`flex ${
          language === "_ar" ? "flex-row-reverse " : " "} flex-wrap gap-6 justify-start items-center`}>
{/* {images&&images?.map((item)=>(
<img src={item?.image} className="rounded-lg" alt="logo"/>

))} */}
     { loading ?
         <div className="flex h-[300px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
    
         otherData?.length>0? 
         
         otherData.map((item,index)=>
(

  <NewsCardComponent  onClick={()=>{handleProjectClick(item?.id)}} key={index}  data={item}/>

))  
:
(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
             {GetAppText("no_data")}
              </div>)}
</div>
</div>
  </div>
  );
};

export default News;
