import React, { useRef } from "react";
import { ButtonComponent } from "../../../components/ButtonComponent";
import SvgComponent from "../../../components/SvgComponent";
import { GetAppText, StyleClass } from "../../../../utils";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Texture4 } from "../../../../assets/svgs/texture4.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation,Autoplay  } from "swiper/modules";
import { modalHandler } from "../../../../store/slices/modalSlice";
import "swiper/css";
import "swiper/css/navigation";
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";
export const AboutDonations = ({data,loading}) => {
  const language = useSelector((state) => state.language.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
const handleClick=()=>{
 
    navigate(`/stories-listing`);
 
}
const swiperRef = useRef();

// const data=[
//   {id:1,name:"Title 1",subName:""},
//   {id:2,name:"Title 2",subName:""},
//   {id:3,name:"Title 3",subName:""},
// ]

const handleProjectClick = (id) => {

  navigate(`/story/${id}`);
};

const handle_share=(item)=>{
  const currentURL = window.location.href;
// console.log("link",`${currentURL}donation-project-details/${item?.id}`)
const fullLink=`${currentURL}story/${item?.id}`
    // Copy the link to the clipboard
    navigator.clipboard.writeText(fullLink).then(() => {
      dispatch(modalHandler({type:"share",msg:GetAppText("link")}));
      setTimeout(()=>{
        dispatch(modalHandler(""));
      },100)
    });

}

  return (
    <div className="w-full h-full">
      
     { loading ?
         <div className="flex h-[400px] w-full justify-center items-center">
         <Loader/>
         </div>
         : 
          <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
      
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          // autoplay={{
          //   delay: 2500, // Set the delay in milliseconds (3 seconds in this case)
          //   disableOnInteraction: false, // Allows autoplay to continue after interaction
          // }}
     
          className="w-full h-full relative"
        >
          {
 loading ?
  <div className="flex  w-full justify-center h-[300px] items-center">
  <Loader/>
  </div>
  : data?.length>0 && data?.map((item,index)=>(
            <SwiperSlide >

    <div key={index} className={`md:flex md:flex-row  flex-col-reverse flex justify-center ${
      language === "_ar" ? "md:flex-row-reverse" : ""
    } `}>
       
<div className="md:w-6/12 relative bg-primary">
<div className="absolute top-0 left-0">
<Texture4 className=""/>
</div>
  <div className="lg:p-16 pb-8 p-10 h-full  flex flex-col justify-between">
    <div className="space-y-4 relative">
    <div className="mb-16">
    <div
      className={`flex justify-between absolute z-20 w-full items-center ${StyleClass()}`}
    >
      <h1 className={`text-white ${language === "_ar" ? "text-right" : "text-left"} font-bold text-F20`}>
        {/* {GetAppText("hope_grows")} */}
        {language === "_ar" ?item?.title_ar:item?.title}
      </h1>
      <ButtonComponent
      title={GetAppText("sharing")}
      tooltip={GetAppText("link_share")}
      onClick={()=>{handle_share(item)}}
      svg={
        <SvgComponent
          name={"ShareSvg"}
          className={"w-4 h-4"}
          stroke={"white"}
          fill={"none"}
        />
      }
      styles={"!py-2 !px-3  !text-F16"}
      type="transparent"
    />
    </div>
</div>
      <h1 className={`text-white ${language === "_ar" ? "text-right" : "text-left"} text-F16 font-light line-clamp-4 tracking-wide leading-6`}>
      {language === "_ar" ?item?.description_ar:item?.description}
      </h1>
      </div>
 
      <div
        className={`flex justify-between pt-5 relative items-center ${StyleClass()}`}
      >
      <div className={`flex items-center  space-x-3 ${StyleClass()}`}>
          <ButtonComponent
            title={GetAppText("read_more")}
            onClick={()=>handleProjectClick(item?.id)}
            type="secondary"
        styles={`!space-x-2 ${language === "_ar" ? "" : "!space-x-reverse"}`}
            svg={
              <SvgComponent
                name={language === "_ar" ? "ArrowLeft" : "ArrowRight"}
                className={"w-5 h-5"}
                fill={"none"}
                stroke={"white"}
              />
            }
          />

          <ButtonComponent
            title={GetAppText("all_stories")}
            type="transparent"
          
            onClick={handleClick}
          />
        </div>
        <div className={`flex flex-row absolute z-50 ${language === "_ar" ? "left-0" : "right-0"} items-center space-x-2`}>
          <div  onClick={() => swiperRef.current?.slidePrev()} className="flex cursor-pointer justify-center items-center p-2 border-2 border-white rounded-full">
            <SvgComponent
              name={"ArrowLeft"}
              className={"w-6 h-6"}
              stroke={"white"}
              fill={"none"}
            />
          </div>
          <div  onClick={() => swiperRef.current?.slideNext()} className="flex cursor-pointer justify-center items-center p-2 border-2 border-white rounded-full">
            <SvgComponent
              name={"ArrowRight"}
              className={"w-6 h-6"}
              stroke={"white"}
              fill={"none"}
            />
          </div>
        </div>
      
      </div>
  
  </div>
</div>
<div className="md:w-6/12 flex ">
<img

 className="w-full object-cover"
 alt="logo"
 src={`${Image_URL}/${item?.image}`}

/>
{/* { item?.media?.map((item,index)=>(
 (index===0 && <img
 key={index}
 className="w-full object-cover"
 alt="logo"
 src={item?.thumbnail}
/>)
))} */}
 
</div>

</div>


   
      </SwiperSlide>
        ))
        // :(!loading && <div className="flex w-full h-[300px] justify-center text-F20 font-semibold items-center">
        //   {GetAppText("no_data")}
        //    </div>)
}
      </Swiper>}
    </div>
  );
};
