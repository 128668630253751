import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation,Autoplay  } from "swiper/modules";
import SvgComponent from "../../../components/SvgComponent";
import { GetAppText, StyleClass } from "../../../../utils";
import { ButtonComponent } from "../../../components/ButtonComponent";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {ReactComponent as Texture} from "../../../../assets/svgs/texture.svg"
import {ReactComponent as Texture2} from "../../../../assets/svgs/texture2.svg"
import { Loader } from "../../../components/Loader";
import { Image_URL } from "../../../../utils/BaseUrl";

const CustomSwiper = ({data,loading}) => {
  const swiperRef = useRef();
  const language = useSelector((state) => state.language.value);
  const navigate = useNavigate();
const handle_learnmore=()=>{
  navigate(`/about-us`);
}
const handle_donate=()=>{
  navigate(`/donation-projects`);
}
  return (
    <div className="w-full h-[650px]">
      <div className="h-full w-full">
     { loading ?
         <div className="flex h-[600px] w-full justify-center items-center">
         <Loader/>
         </div>
         :data && data?.length>0? 
         <Swiper
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
      
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation, Autoplay]} // Include Autoplay
          autoplay={{
            delay: 3000, // Set the delay in milliseconds (3 seconds in this case)
            disableOnInteraction: false, // Allows autoplay to continue after interaction
          }}
     
          className="w-full h-full relative"
        >
          {/* <div>
          {
            swiperImages?.map((item)=>(
              <SwiperSlide>
                <div className={`flex h-full ${language === "_ar" ? "" : "flex-row-reverse space-x-reverse"} bg-custom-gradient-background `}>
              <img
              key={item?.id}
                src={item?.svg}
                alt="Slides"
                className="w-6/12 h-full object-cover "
                style={{clipPath: `${language === "_ar" ? "polygon(0 0, 86% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 100%, 16% 100%)"}
                  `}}
              />
              

              <div className={`w-6/12 h-full  relative ${language === "_ar" ? "sm:pr-20 pr-3" : "sm:pl-20 pl-3"}   py-6 flex  items-center`}>
      <div className="absolute bottom-0 left-[-90px]">
    
        <Texture className="object-cover"/>
      </div>
      <div className="absolute top-[-20px] right-0 ">

            <Texture2/>
      </div>
      <div className="h-full  flex flex-col  md:pt-44 pt-32 space-y-4">
        <div className={`flex ${language === "_ar" ? "flex-row-reverse" : ""}  text-F20 text-white`}>
        [الحج: 77]
        </div>
        <div className={`md:text-F39  ${language === "_ar" ? "text-right" : "text-left"} text-F30  font-extrabold text-white`}>
        <div className="">
        {ayat1}
      </div>
      <div className="text-secondary"> {ayat2}</div>

      </div>
      </div>
      </div>
      </div>
            </SwiperSlide>
            ))
          }
       
 

</div> */}
{  data?.length>0? 
         data?.map((item,index)=>(
          <SwiperSlide>
            <div  key={index} className={`flex h-full ${language === "_ar" ? "" : "flex-row-reverse space-x-reverse"} bg-custom-gradient-background `}>
          <img
     
            src={`${Image_URL}/${item?.image}`}
            alt="Slides"
            className="w-6/12 h-full object-cover "
            style={{clipPath: `${language === "_ar" ? "polygon(0 0, 86% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 100% 0, 100% 100%, 16% 100%)"}
              `}}
          />
          

          <div className={`w-6/12 h-full  relative ${language === "_ar" ? "sm:pr-20 pr-3" : "sm:pl-20 pl-3"}   py-6 flex  items-center`}>
  <div className="absolute bottom-0 left-[-90px]">

    <Texture className="object-cover"/>
  </div>
  <div className="absolute top-[-20px] right-0 ">

        <Texture2/>
  </div>
  <div className="h-full  flex flex-col  md:pt-40 pt-28 space-y-4">
    <div className={`flex ${language === "_ar" ? "flex-row-reverse" : ""}  text-F20 text-white`}>
    {language === "_ar" ? item?.other_text_ar:item?.other_text}
    </div>
    <div className={`md:text-F39  ${language === "_ar" ? "text-right" : "text-left"} text-F30  font-extrabold text-white`}>
    <div className="">
    {language === "_ar" ? item?.title_ar:item?.title}
  </div>
  <div className="text-secondary">  {language === "_ar" ? item?.subtitle_ar:item?.subtitle}</div>

  </div>
  </div>
  </div>
  </div>
        </SwiperSlide>
        ))
:
(!loading && <div className="flex w-full justify-center text-F20 font-semibold items-center">
           {GetAppText("no_data")}
              </div>)}
 

          {/* Custom Navigation Buttons */}
          <div className="">
            <div
              className={`absolute z-50  sm:bottom-6 bottom-4  ${
                language === "_ar" ? " sm:right-20 right-4" : "sm:left-20 left-4 "
              }`}
            >
              <div
                className={`flex items-center space-x-2.5 sm:mb-24 mb-14 ${StyleClass()}`}
              >
                <ButtonComponent
                onClick={handle_donate}
                  title={GetAppText("donate")}
                  styles={"!px-6  !text-F18"}
                  type="secondary"
                />
                <ButtonComponent
                onClick={handle_learnmore}
                  title={GetAppText("learn_more")}
                  type="transparent"
                  styles={"!px-6  !text-F18"}
                />
              </div>
            </div>
            <div
              className={`absolute  z-50 sm:bottom-10 bottom-6
                 ${
                language === "_ar" ? " sm:right-20 right-4" : "sm:left-20 left-4 "
              }
                `}
            >
              <div className="flex items-center space-x-2.5 right-0">
                <div
                  className="border-2 border-white p-2 rounded-full cursor-pointer"
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SvgComponent
                    name="ArrowLeft"
                    stroke={"white"}
                    fill={"none"}
                    className="w-6 h-6"
                  />
                </div>
                <div
                  className="border-2 border-white p-2 rounded-full cursor-pointer"
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SvgComponent
                    name="ArrowRight"
                    stroke={"white"}
                    fill={"none"}
                    className="w-6 h-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </Swiper>:
      (!loading && <div className="flex w-full justify-center h-full bg-custom-gradient-background text-white pt-10 text-F24 font-semibold items-center">
        {GetAppText("no_data")}
           </div>)}
      </div>
    </div>
  );
};

export default CustomSwiper;
